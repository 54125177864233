.otpinput-field {
    width: 45px !important;
    height: 45px !important;
    border-radius: 15px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    background-color: white;
    color: #252b5c;
    font-weight: 500 !important;
}




.otp-input-div {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.OTP-head {
    font-size: 22px;
    font-weight: 500;
}

.OTP-text {
    color: #929294;
}

.Resend-otp-div {
    color: #929294;
    font-size: 13px;
}

.Resend-otp-text {
    color: #8b49f5;
    font-weight: 600;
    cursor: pointer;
}

.otp-timer-div {
    height: 40px;
    width: 90px;
    border-radius: 20px;
    background-color: #f5f4f8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    gap: 6px;
}

.modal-content {
    border-radius: 18px !important;
}