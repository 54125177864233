.login-home-navbar-main-Divv {
  width: 100%;
  height: 100px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prof-photo-imgg-smile-circle {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50px;
}

.vocation {
  color: #ffffff;
  font-size: 14px;
  line-height: 5px;
}

.Home-logo-imgfooter {
  width: 100px;
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}

.dropdown-item:hover {
  background-color: #9c04f5 !important;
}

.Home-spann-sec-para-wwt {
  text-decoration: none !important;
}

.Home-spann-sec-para-wwt:hover {
  color: #9c04f5 !important;
}

.Home-spann-sec-para-wwt.active {
  color: #9c04f5;
}

.nonne {
  display: none;
}

.login-home-containerr-div {
  width: 80%;
  height: 69px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Home-logo-divv-ppx {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Home-spann-of-title-hutt {
  font-weight: 700;
  font-size: 25px;
  color: white;
}

.Home-about-contactus-divv {
  display: flex;
  align-items: center;
  gap: 25px;
}

.Navbar-main-divv-ppx {
  display: flex;
  gap: 80px;
}

.Profile-photo-sec-Main {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Home-spann-sec-para-wwt {
  font-weight: 500;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

.prof-photo-Spann-divv {
  display: flex;
  align-items: center;
}

.prof-photo-spnnn-smile-circle {
  font-weight: 350;
  font-size: 16px;
  color: white;
}

.prof-dropdownn-Saddd-divv {
  background-color: black;
  border: none;
}

.hamburger-menu-main-sec-divv {
  display: none;
}

.hamburger-menu-home-divv {
  display: none;
}

.Home-spann-home-span {
  text-decoration: none !important;
  color: white;
  display: flex;
  width: 100%;
}

.Button-divv-prop {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  height: 50px;
}
.Property-p-cvv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.btn-prop-later-main {
  height: 35px;
  border-radius: 8px;
  width: 90px;
  font-size: 15px;
}
@media screen and (max-width: 835px) {
  .Navbar-main-divv-ppx {
    display: flex;
    gap: 40px;
  }
  .Home-logo-imgfooter {
    width: 70px;
  }
}

@media screen and (max-width: 558px) {
  .Home-about-contactus-divv {
    display: none;
  }

  .hamburger-menu-main-sec-divv {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 16vh;
    background-color: black;
    padding-left: 20px;
    padding-top: 10px;
  }

  .nonne {
    display: none !important;
  }

  .hamburger-menu-home-divv {
    display: block;
  }

  .login-home-containerr-div {
    width: 90%;
  }

  .prof-photo-spnnn-smile-circle {
    display: none;
    height: 45px;
    width: 45px;
  }

  .hamburger-menu-home-divv {
    background-color: #6c757d;
    border-radius: 10px;
  }

  .prof-dropdownn-Saddd-divv dropdown-toggle btn btn-secondary {
    background-color: black;
    border: none;
  }
  .Home-logo-imgfooter {
    width: 70px;
  }
  .Home-spann-sec-para-wwt {
    font-size: 14px;
  }
}
@media screen and (max-width: 428px) {
  .Home-spann-sec-para-wwt {
    font-size: 12px;
  }
  .prof-photo-imgg-smile-circle {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 415px) {
  .hamburger-menu-main-sec-divv {
    height: 120px;
    padding-left: 2rem;
  }
}

@media screen and (max-width: 360px) {
  .Profile-photo-sec-Main {
    gap: 8px;
  }
}
