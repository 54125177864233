.main-div-Thereviewproces {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #fffae2;
  width: 100%;
}
.Wo-ve-are-sec {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background: #fffae2;
  width: 100%;
  height: 91vh;
}
.main-second-thord-thar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.main-sec-Thereviewproces {
  display: flex;
  flex-direction: column;
}
.missing-span {
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
}
.review-span {
  font-size: 25px;
  width: 100%;
}
.op-elac {
  display: flex;
  flex-direction: column;
}
.Andp-what-span {
  font-size: 60px;
  width: 80%;
}
.ek-ka-ek-bug-1 {
  font-size: 17px !important;
  width: 100%;
  display: flex;
  gap: 5px;
}
.charge-div {
  display: flex;
  flex-direction: column;
}
.host-revi-span {
  font-size: 25px;
  width: 100%;
  line-height: 40px;
}
.download-span {
  font-size: 16px;
}
@media screen and (max-width: 540px) {
  .review-span {
    font-size: 25px;
  }
  .host-revi-span {
    font-size: 25px;
  }
}
@media screen and (max-width: 428px) {
  .Wo-ve-are-sec {
    height: 100%;
  }
}
