.mainnn-divvv-dusri-ser {
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0.8rem;
  width: 300px;
}

.map-upar-div-serach {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.Searchedpr-main-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Prop-home-sera {
  width: 270px;
  height: 150px;
  object-fit: cover;
}

@media screen and (max-width: 1280px) {
  .map-upar-div-serach {
    grid-template-columns: repeat(3, 1fr);
  }

  .Prop-home-sera {
    width: 100%;
  }
}

@media screen and (max-width: 912px) {
  .map-upar-div-serach {
    grid-template-columns: repeat(3, 1fr);
  }

  .Prop-home-sera {
    width: 230px;
  }
}

@media screen and (max-width: 820px) {
  .map-upar-div-serach {
    grid-template-columns: repeat(3, 1fr);
  }

  .Prop-home-sera {
    width: 220px;
  }
}

@media screen and (max-width: 786px) {
  .map-upar-div-serach {
    grid-template-columns: repeat(2, 1fr);
  }

  .Prop-home-sera {
    width: 310px;
  }
}

@media screen and (max-width: 540px) {
  .map-upar-div-serach {
    grid-template-columns: repeat(2, 1fr);
  }

  .Prop-home-sera {
    width: 200px;
  }
}

@media screen and (max-width: 428px) {
  .map-upar-div-serach {
    grid-template-columns: repeat(1, 1fr);
  }

  .Prop-home-sera {
    width: 100%;
  }
}
