.Footer-main-section {
  display: flex;
  width: 100%;
  background: #211b24;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.homefooterlogo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  flex-direction: column;
}

.contactusmain {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contactusmain > a {
  text-decoration: none;
}

.copy-right-span {
  color: white;
}

.Footer-main-div {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
}

.ex-tra-d-iv {
  width: 100%;
  height: 1px;
  border: 1px solid #383838;
  margin-top: 3rem;
}

.main-thard-all-div {
  display: flex;
}

.com-gmail {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.footer-logo-img {
  width: 110px;
}

.footer-img-span-div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  flex-direction: column;
  width: 150px;
}

.home-span-main-logo {
  font-weight: 700;
  font-size: 32.8722px;
  /* width: 138px; */
  height: 45px;
  color: white;
}

.Lorem-ipsum-span {
  width: 300px;
  margin-top: 1rem;
}

.span-lorem-clls-1 {
  font-weight: 500;
  font-size: 14px;
  color: white;
}

.Read-More-div {
  margin-top: 1rem;
}

.Read-More-span {
  color: #fee24c;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

.second-home-span {
  font-weight: 600;
  font-size: 18px;
  color: white;
}

.Social-akkk {
  color: white;
}

.to-by-to-div {
  display: flex;
}

.Newsroom-span {
  font-weight: 400;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.Footur-contact-us {
  color: white;
}

.Learn-about-new-span {
  font-weight: 400;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.Learn-about-new-span:hover {
  color: #fee24c;
}

.Newsroom-span:hover {
  color: #fee24c;
}

.logo-main-span-div {
  margin-right: 4rem;
}

.all-span-main-div {
  display: flex;
}

.alo-to-al {
  display: flex;
}

.span-div {
  margin-top: 0.5rem;
}

.Fhass-book-img {
  width: 33px;
  height: 33px;
}

.in-book-img {
  width: 47px;
  height: 38px;
}

.Fhass-book-div {
  margin-top: 1rem;
  display: flex;
  gap: 5px;
}

.main-thard-all-div {
  display: flex;
  gap: 2rem;
}

.all-span-main-div {
  display: flex;
  gap: 3rem;
}

.to-by-to-div {
  gap: 2rem;
}

@media screen and (max-width: 1115px) {
  .all-span-main-div {
    margin-right: 1rem;
  }

  .logo-main-span-div {
    margin-right: 1rem;
  }

  .Support-all-span {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 1080px) {
  .Newsroom-span {
    font-size: 12px;
  }

  .Learn-about-new-span {
    font-size: 12px;
  }

  .main-thard-all-div {
    display: flex;
    gap: 2rem;
  }

  .all-span-main-div {
    display: flex;
    gap: 2rem;
  }

  .to-by-to-div {
    gap: 2rem;
  }

  .calling-lag {
    font-size: 14px;
    text-align: center;
    width: 110px;
    font-weight: 600;
  }
}

@media screen and (max-width: 912px) {
  .main-thard-all-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .all-span-main-div {
    gap: 3rem;
  }
}

@media screen and (max-width: 884px) {
  .main-thard-all-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .all-span-main-div {
    gap: 3rem;
  }
}

@media screen and (max-width: 769px) {
  .Footer-main-div {
    width: 90%;
  }
}

@media screen and (max-width: 540px) {
  .Footer-main-section {
    height: 100%;
  }

  .footer-img-span-div {
    width: 100%;
  }

  .all-span-main-div {
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
  }

  .Footer-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo-main-span-div {
    margin-right: 0rem;
  }

  .to-by-to-div {
    gap: 2;
  }

  .Social-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Support-all-span {
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-right: 0rem;
  }

  .all-span-main-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 0rem;
  }

  .main-thard-all-div {
    flex-direction: column;
    display: flex;
  }

  .alo-to-al {
    display: flex;
    gap: 0rem;
    flex-direction: column;
  }

  .to-by-to-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .Footer-main-section {
    height: 100%;
  }

  .footer-img-span-div {
    width: 100%;
  }

  .all-span-main-div {
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
  }

  .Footer-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo-main-span-div {
    margin-right: 0rem;
  }

  .to-by-to-div {
    gap: 2;
  }

  .Social-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Support-all-span {
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-right: 0rem;
  }

  .all-span-main-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 0rem;
  }

  .main-thard-all-div {
    flex-direction: column;
    display: flex;
  }

  .alo-to-al {
    display: flex;
    gap: 0rem;
    flex-direction: column;
  }

  .to-by-to-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 391px) {
  .Footer-main-section {
    height: 100%;
  }
}
