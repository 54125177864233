.FreeTime-main-section {
    display: flex;
    width: 100%;
    background: #211B24;
    height: 100%;
    align-items: center;
    justify-content: center;
}



.FreeTime-main-div {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 2rem;
    gap: 10rem;
    justify-content: center;
    margin-bottom: 2rem;
}

.timeshare-span-div-2 {
    width: 300px;
}

.timeshare-span {
    color: white;
    font-weight: 700;
    font-size: 55px;
}

.freetime-img-1 {
    width: 350px;
}

.buttn-to {
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Time-share-no {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
}

.discovar-that {
    color: white;
    font-size: 30px;
    font-weight: 500;

}

.adipiscing-span {
    color: white;

}

.Know-More-btn-too {
    background: #9C04F5;
    border-radius: 4px;
    width: 360px;
    height: 45px;
    color: white;
}


.Know-More-Fit {
    background: #9C04F5;
    border-radius: 4px;
    width: 360px;
    height: 45px;
    color: white;
}

@media screen and (max-width: 888px) {
    .FreeTime-main-div {
        flex-direction: column;
        gap: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .FreeTime-main-div {
        display: flex;
        gap: 5rem;
        width: 90%;
    }

    .timeshare-span {
        font-size: 40px;
    }
}

@media screen and (max-width: 428px) {
    .FreeTime-main-section {

        height: 100%;
    }

    .timeshare-span {
        font-size: 30px;
    }

    .timeshare-span-div-2 {
        text-align: center;


    }

    .freetime-img-1 {
        width: 300px;
    }
}

@media screen and (max-width: 360px) {
    .freetime-img-1 {
        width: 250px;
    }

    .timeshare-span {
        font-size: 27px;
    }

    .timeshare-span-div-2 {
        text-align: center;
    }
}