.download-section-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffae2;
  height: 70vh;
}
.time-span-text {
  font-size: 22px;
  font-weight: 700;
}
.vection-span-rental {
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
}
.only-span {
  border-bottom: #211b24 2px solid;
}
.half-star-div {
  display: flex;
  width: 20%;
  justify-content: space-between;
}
.Fa-span {
  color: #fee24c;
  margin-left: 0.5rem;
}
.ek-ka-ek-bug {
  font-size: 15px;
}
.timeshare-span-div {
  width: 65%;
}
.interest-div {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.ek-ka-ek-chaing {
  font-size: 18px;
}
.time-share-middle {
  width: 85%;
}
.Timeshare-main-section-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffae2;
}
.para-main-section-divv,
.para-span-time-share,
.organs-span {
  font-family: "Trebuchet MS" !important;
  font-style: normal;
  font-size: 19px;
  line-height: 29px;
  color: #211b24;
}
.time-share-middle {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.first-point-divv {
  gap: 12px;
  width: 90%;
}
.first-span-tagg-ppz {
  font-weight: 500;
}
.para-main-section-divv {
  font-weight: 500;
}
.para-span-time-share {
  font-weight: 500;
}
.second-point-divv {
  margin-left: 1.5rem;
}
.download-div {
  display: flex;
  flex-direction: column;
}
.download-div-main {
  width: 85%;
  display: flex;
  align-items: center;
  margin: 1rem;
}

.italic-taxt {
  font-size: 15px;
  font-style: italic;
}
.free-span-hoe {
  font-size: 18px;
  font-weight: 500;
}
.bug-div {
  display: flex;
  flex-direction: column;
}
.blaze-span {
  font-size: 18px;
}
.blaze-span-1 {
  font-size: 17px;
}
.extttt-div {
  display: flex;
  flex-direction: column;
}
.ek-ka-ek-ching {
  font-size: 17px;
}
.pila-star {
  color: #fee24c !important;
}
.time-itle-tex {
  font-size: 18px;
  font-weight: 700;
}
.half-star-div {
  width: 40%;
}
@media screen and (max-width: 884px) {
  .half-star-div {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .half-star-div {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .timeshare-span-div {
    width: 100%;
  }
  .download-section-2 {
    height: 100%;

    .time-span-text {
      margin-top: 1rem;
    }
  }
}
@media screen and (max-width: 428px) {
  .download-div-main {
    height: 100%;
    width: 85%;
    margin-bottom: 3rem;
  }
  .half-star-div {
    width: 100%;
  }
}
