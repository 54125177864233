.Property-ppx-cvv-00 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 40px; */
  height: auto;
  width: 100%;
}
.spandivv-ppx-property-00 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 0.5rem;
}
.btn-property-del-main-09 {
  background-color: #000;
  color: #ffffff;
  height: 35px;
  border-radius: 8px;
  width: 120px;
  font-size: 15px;
  cursor: pointer;
}
.btn-pro-Del-main-0 {
  height: 35px;
  border-radius: 8px;
  width: 120px;
  font-size: 15px;
  cursor: pointer;
}
