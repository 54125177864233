.PersonalInformation-sect {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PersonalInformation-div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6rem;

  margin: 2rem;
}

.Personal-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.boy-img-1 {
  width: 173px;
  height: 173px;
  border-radius: 100px;
  object-fit: cover;
}

.code-mo-div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Richard-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.svg-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #211b24;
  border-radius: 4px;
  color: white;
  width: 178px;
  height: 45px;
  gap: 1rem;
}

.edit-icon-svg {
  background: #9c04f5;
  border-radius: 4px;
  width: 178px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  gap: 1rem;
}

.edit-add-btn-svg-div {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.cont-span {
  font-weight: 600;
  font-size: 20px;
  color: #211b24;
}

.mo-bile-span {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
}

@media screen and (max-width: 1024px) {
  .PersonalInformation-div {
    gap: 2rem;
  }

  .svg-btn-icon {
    width: 150px;
  }

  .edit-icon-svg {
    width: 150px;
  }

  .My-profile-div {
    width: 195px;
  }
}

@media screen and (max-width: 884px) {
  .PersonalInformation-div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;
  }

  .cont-span {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .PersonalInformation-div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;
  }
}

@media screen and (max-width: 428px) {
  .PersonalInformation-div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;
    width: 31%;
  }

  .PersonalInformation-sect {
    width: 0%;
  }

  .edit-add-btn-svg-div {
    display: flex;
    flex-direction: column;
  }

  .Personal-span {
    font-size: 16px;
  }

  .Richard-span {
    font-size: 16px;
    display: flex;
    justify-content: center;
    width: 200px;
  }
}

@media screen and (max-width: 320px) {
  .PersonalInformation-div {
    width: 15%;
  }
}