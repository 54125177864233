body {
  font-family: Arial, sans-serif;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
.invoice-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.invoice-header h1 {
  font-size: 20px;
  margin: 0;
  font-family: "BeVietnam-SemiBold";
}
.invoice-header p {
  margin: 0;
  font-family: "BeVietnam-SemiBold";
  font-size: 16px;
  color: #888;
}
.invoice-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.invoice-details .invoice-address {
  flex: 1;
}
.invoice-details .invoice-address h3 {
  margin: 0;
  font-size: 18px;
}
.invoice-details .invoice-address p {
  margin: 0;
  font-size: 14px;
  color: #888;
}
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
}
.invoice-table th,
.invoice-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.invoice-table th {
  background-color: #f2f2f2;
  text-align: left;
  color: #555;
}
.invoice-table td {
  text-align: left;
}
.invoice-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.invoice-total p {
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
}
.invoice-footer {
  margin-top: 40px;
  text-align: center;
}
.invoice-footer p {
  margin: 0;
  font-size: 14px;
  color: #888;
}
.amount {
  margin-top: 20px;
}
.description-span {
  font-weight: 700;
  font-size: 14px;
  color: #211b24;
  display: flex;
  width: 440px;
}

.income-span {
  font-weight: 700;
  font-size: 14px;
  color: #211b24;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Date-service-main {
  display: flex;
  gap: 8rem;
}
.div-inc-dte-spann {
  display: flex;
  width: 120px;
  justify-content: center;
}
.date-inc-main-divv {
  display: flex;
  gap: 22rem;
}
.underline-divv-invoice {
  border: 1px solid #e4e4e4;
}
.book-underline-divv-invoice {
  border: 1px solid #e4e4e4;
  width: 70%;
}
.underline-setting-divv {
  display: flex;
  justify-content: flex-end;
}
.inc-main-divv {
  display: flex;
  gap: 22.8rem;
}
.Total-main-uuu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
}
.Total-tagg-span {
  font-weight: 600;
  font-size: 20px;
}
@media screen and (max-width: 640px) {
  .invoice-details,
  .invoice-header {
    flex-direction: column;
    gap: 1rem;
  }
}
