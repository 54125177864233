.Home-page-main-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #fffae2;
}

.Home-page-main-div {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  gap: 10rem;
  justify-content: center;
  margin-bottom: 7rem;
}

.the-span {
  font-weight: 700;
  font-size: 40px;
}

.Find-span-div-2 {
  width: 300px;
}

.Know-More-btn-qaz {
  background-color: #9c04f5;
  border-radius: 4px;
  width: 140px;
  height: 45px;
  border: none;
  color: white;
}

.slide-one-hu {
  height: 100px;
  width: 100px;
}

.slider-setting-div {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 500px !important;
  width: 500px !important;
  outline: none !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none !important;
}

.slider-par-viv {
  width: 100% !important;
}

.slick-dots {
  bottom: -20px;
  right: 650px;
}

.swipar-bol-te-humko {
  width: 40%;
}

.wrap-ke-img {
  display: block;
  width: 30%;
}

.slick-dots li button {
  border-radius: 0px !important;
}

@media screen and (max-width: 884px) {
  .Home-page-main-div {
    gap: 1rem;
    margin-bottom: 4rem;
  }

  .the-span {
    font-size: 35px;
  }

  .Home-page-main-section {
    height: 100%;
  }

  .Find-span-div-2 {
    width: 90%;
  }

  .swipar-bol-te-humko {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .Home-page-main-div {
    gap: 1rem;
    margin-bottom: 7rem;
  }

  .the-span {
    font-size: 30px;
  }

  .Home-page-main-section {
    height: 100%;
  }

  .swipar-bol-te-humko {
    width: 55%;
  }
}

@media screen and (max-width: 540px) {
  .the-span {
    font-size: 25px;
  }

  .Home-page-main-section {
    height: 100%;
  }

  .Home-page-main-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .swipar-bol-te-humko {
    width: 70%;
  }
}
