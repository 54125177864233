.Model-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.8;
}
.ChangePassword-main-section {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-width: 25rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: white;
  max-height: 38rem;
}
.ekj-pAsword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Password-ek-or {
  display: flex;
  width: 100%;
}
.ChangePassword-main-div {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
}
.svg-chaing {
  display: flex;
  width: 90%;
  justify-content: flex-end;
}
.font-chang {
  color: #211b24;
  font-weight: 700;
  font-size: 20px;
}
.clip-mar {
  margin-right: 0.5rem;
}
.Reset-Password-btn-last {
  background: #9c04f5;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  color: white;
  border: none;
}
.Close-btnn-modall-divv {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-span-div {
  padding-left: 15px;
}
.close-Address-main-span {
  width: 30px;
  height: 30px;
}
.Address-close-popup-divv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  cursor: pointer;
}
.mastu-to-eroor {
  display: flex;
  align-items: flex-start;
  color: red;
  width: 95%;
}
@media screen and (max-width: 428px) {
  .ChangePassword-main-section {
    width: 90%;
  }
  .svg-p-div {
    width: 310px;
  }
  .Reset-Password-btn-last {
    width: 310px;
  }
  .Password-ek-or {
    width: 87%;
  }
}
