.stripe-payment-home-divv {
  width: 30%;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.stripe-payment-div-card {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.Payment-divv-section {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.Payment-divv-sec-mid {
  width: 90%;
  /* background-color: aqua; */
  height: 100px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 40px;
}
.spandivv-ppx-payment {
  font-size: 40px;
  font-weight: 600;
}
.btn-pay-stripe-main {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  background-color: #9c04f5;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.Button-main-divv-pay {
  display: flex;
  gap: 20px;
  width: 290px;
  justify-content: center;
  height: 52px;
}
.btn-stripe-later-main {
  width: 60px;
  height: 40px;
  border-radius: 4px;
}
.Payment-ppx-cvv-hh {
  display: flex;
  width: 360px;
  justify-content: center;
  height: 46px;
}

.Payment-card-main-divv-ppx {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
