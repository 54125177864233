.imptant-sec-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #9c04f5;
}

.alll-mainn-divv {
  display: flex;
  flex-direction: column;
}

.alll-mainn-divv > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contain-impotant-div {
  gap: 2rem;
  display: flex;
  width: 100%;
}

.How-it-all {
  display: flex;
  width: 99%;
  align-items: center;

  gap: 1rem;
}

.Home-and-saport-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Vivamus-et-consequat-span > span {
  color: white;
  font-size: 14px !important;
}

.imptant-div-main {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
}

.information-span {
  font-weight: 700;
  font-size: 30px;
  color: white;
}
.alll-mainn-divv {
  gap: 1rem;
  padding-top: 1rem;
}

@media screen and (max-width: 1280px) {
  .Home-and-saport-nav {
    flex-direction: column;
    align-items: flex-start;
  }
  .alll-mainn-divv > div {
    flex-direction: column;
  }
}

@media screen and (max-width: 888px) {
  .imptant-sec-main {
    height: 100%;
  }
}
