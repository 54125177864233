.MAin-slider-containor-divv {
  width: 100%;
}

.homeparentmain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular2 {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.circular2 > * {
  color: #9c04f5 !important;
}

.profile-imgg-name {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.Home-slider-merge-divv {
  display: flex;
  gap: 50px;
  width: 80%;
  padding: 1rem 1rem;
}

.SwiperProperty-home-divv-ppx {
  height: 100%;
}

.Slider-second-div-ppx {
  height: 100%;
  /* background-color: yellow; */
  width: 100%;
}

.grandview-texas-us-span {
  /* font-family: 'Be Vietnam'; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
}

.Texas-Grand-Middle-div-ppx {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Accecible-parking-us-span {
  font-weight: 500;
  font-size: 14px;
  color: #838383;
}

.two-rs-us-span {
  font-weight: 500;
  font-size: 24px;
}

.per-main-div {
  font-weight: 400;
  font-size: 18px;

  color: #838383;
}

.night-per-main-div {
  font-weight: 400;
  font-size: 15px;

  color: #838383;
}

.rating-four-texas-div {
  background-color: #fee24c;
  width: 40px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Star-rating-span-ppx {
  font-weight: 400;
  font-size: 13px;
}

.merge-div-rating-tff {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  width: 45%;
}

.Three-four-four-span-ppx {
  font-weight: 400;
  font-size: 16px;
}

.report-people-main-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Underline-main-divv {
  border-radius: 5px;
  width: 100%;
  height: 1px;
  /* outline: none; */
  background-color: #dfdfdf;
}

.profile-plus-name-divv {
  width: 160px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  gap: 10px;
}

.profile-Jermo-name-ppx {
  font-weight: 600;
  font-size: 18px;
}

.entire-home-profile {
  display: flex;
  justify-content: space-between;
}

.Book-now-main-divv-ppx {
  width: 266px;
  /* background-color: yellowgreen;     */
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Book-second-divv-btnn {
  width: 131px;
  height: 45px;
  background-color: #9c04f5;
  border-radius: 5px;
  border: transparent;
  color: white;
  font-weight: 700;
  font-size: 15px;
}

.Book-phone-divv-btnn {
  width: 47px;
  height: 47px;
  border-radius: 30px;
  background-color: black;
  border: black;
}

.Book-Message-divv-btnn {
  width: 47px;
  height: 47px;
  border-radius: 30px;
  background-color: #9c04f5;
  border: #9c04f5;
}

.Lorem-span-main-divv {
  width: 100%;
}

.Lorem-read-mor-btnn {
  border: transparent;
  background-color: transparent;
  color: #9c04f5;
}

.Lorem-imgg-downarrow {
  margin-left: 0.5rem;
}

.Read-less-arrow-span {
  font-weight: 500;
  font-size: 16px;
  color: #9c04f5;
}

.Guests-bed-nine-bats-ppx {
  display: flex;
  gap: 18px;
  align-items: center;
  /* margin-left: 5px; */
}

.Guests-spann-divv-ppx {
  display: flex;
  gap: 6px;
}

.Guests-fourten-spann-ppx {
  font-weight: 700;
}

.Guests-bed-fourteen-beds-ppx {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}

.What-this-span-offer-para {
  font-weight: 700;
  font-size: 20px;
}

.what-this-list-btn {
  width: auto;
  height: auto;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #afafaf;
  color: #211b24;
  padding: 8px 2rem;
}

.What-list-divvv-btnnss {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.Find-on-map-span-para {
  font-weight: 600;
  font-size: 22px;
}

.Avail-down-arr-dec-divv {
  font-weight: 600;
  font-size: 22px;
}

.Availability-four-dec-divv {
  display: flex;
  width: 100%;
}

.Availability-house-main-divv {
  width: 100%;
  /* background-color: #9c04f5; */
}

.Avail-down-spn-divv {
  width: 100%;
}

.Avail-left-arrow {
  /* width: 12px; */
  height: 12px;
}

.Avail-down-arr-imgg-divv {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.four-dec-span-twenty {
  color: #595959;
}

.Four-underline-spnn-divv {
  border-radius: 5px;
  width: 100%;
  height: 1px;
  /* outline: none; */
  background-color: #dfdfdf;
}

.Both-Merge-Avail-House-main {
  display: flex;
  width: 100%;
  gap: 40px;
  /* justify-content: space-between; */
}

.Health-safety-four-dec-divv {
  display: flex;
}

.Health-safety-spn-divv {
  width: 100%;
}

.Health-safety-arr-dec-divv {
  font-weight: 600;
  font-size: 22px;
}

.Health-safety-left-arrow {
  height: 12px;
}

.Reviews-spnn-writting-tagg {
  font-weight: 600;
  font-size: 22px;
}

.adults-and-children {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.Reviews-profile-imgg {
  width: 35px;
  height: 35px;
  border-radius: 18px;
}

.Reviews-jermo-bell-mian-divv {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.Reviews-see-all-rating-main-div {
  width: 100%;
}

.gmap_canvas {
  width: 100%;
  height: 200px;
}

.report-flag-div-span {
  cursor: pointer;
}

.Jerome-prof-rating-star-divv {
  display: flex;
  gap: 3px;
}

.lorem-jermo-spn-divv-aat {
  color: #595959;
}

.Jerome-Bell-spnn-name {
  font-weight: 500;
  font-size: 19px;
}

.jermo-reviews-date-june-divv {
  display: flex;
}

.Bell-rev-date-june-divv {
  width: 100%;
}

.date-jermo-text-june-divv {
  width: 102px;
}

.mapouter {
  border-radius: 3 5px;
}

.add-btn-Unavailable {
  background-color: black;
  color: white;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  gap: 0.5rem;
  outline: none;
}

.ok-date-unavilebal {
  display: flex;
  gap: 2rem;
}

.date-unavilebal-ho-gai {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.delet-bhi-kar-sakte-he {
  display: flex;
  gap: 1rem;
}
.img-tiltle-main-dixx-ppx::-webkit-scrollbar {
  display: none;
}
.imgg-divv-title-bath {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
  border-radius: 4px;
  border: 2px solid #ececec;
  width: 150px;
  height: 100px;
  justify-content: center;
  gap: 0.5rem;
}
.img-bath-main-dixx-ppx {
  overflow-x: scroll;
  display: flex;
  gap: 20px;
  width: 100%;
}
.All_baths_and_beds {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.img-bath-main-dixx-ppx::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1024px) {
  .homeparentmain {
    display: block;
    /* display: flex !important;
        align-items: center !important; */
  }

  .rating-four-texas-div {
    width: 71px;
  }

  .Home-slider-merge-divv {
    gap: 40px;
  }
}

@media screen and (max-width: 913px) {
  .homeparentmain {
    display: block;
    /* display: flex !important;
        align-items: center !important; */
  }

  .rating-four-texas-div {
    width: 71px;
  }

  .Home-slider-merge-divv {
    gap: 40px;
  }
}

@media screen and (max-width: 884px) {
  .homeparentmain {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .MAin-slider-containor-divv {
    width: 95%;
  }

  .rating-four-texas-div {
    width: 71px;
  }
}

@media screen and (max-width: 768px) {
  .Home-slider-merge-divv {
    display: flex;
    flex-direction: column;
  }

  .leftproductdetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .MAin-slider-containor-divv {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .rating-four-texas-div {
    width: 45px;
    height: 23px;
  }

  .date-jermo-text-june-divv {
    width: 182px;
  }

  .profile-plus-name-divv {
    margin-top: 2rem;
  }

  .MAin-slider-containor-divv {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
  }

  .Home-slider-merge-divv {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .swiper-slide swiper-slide-active {
    width: 395px;
    margin-right: 10px;
  }

  .yexas-merge-div {
    width: 45%;
  }

  .Three-four-four-span-ppx {
    font-size: 14px;
  }

  .grandview-texas-us-span {
    font-size: 17px;
  }

  .entire-home-profile {
    width: 100%;
    gap: 40px;
    margin-top: 1rem;
  }

  .Book-now-main-divv-ppx {
    flex-wrap: wrap;
    gap: 37px;
  }

  .entire-jerome-bell-div {
    font-size: 15px;
  }

  .mapouter {
    width: 100%;
  }

  .Health-safety-main-divv {
    width: 100%;
  }

  .adults-and-children {
    width: 100%;
  }

  .Guests-bed-fourteen-beds-ppx {
    gap: 13px;
  }

  .Find-on-map-main-divv {
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .entire-home-profile {
    width: 100%;
    gap: 13px;
  }

  .Three-four-four-span-ppx {
    font-size: 13px;
  }

  .merge-div-rating-tff {
    gap: 5px;
  }

  .Book-now-main-divv-ppx {
    flex-wrap: wrap;
    gap: 8px;
  }
}

@media screen and (max-width: 375px) {
  .Both-Merge-Avail-House-main {
    gap: 15px;
  }
}

@media screen and (max-width: 360px) {
  .Both-Merge-Avail-House-main {
    gap: 7px;
  }
}

@media screen and (max-width: 320px) {
  .entire-home-profile {
    gap: 8px;
  }

  .Avail-down-arr-dec-divv {
    font-size: 20px;
  }

  .Jerome-Bell-spnn-name {
    font-size: 15px;
  }

  .rating-four-texas-div {
    width: 50px;
    height: 23px;
  }

  .Three-four-four-span-ppx {
    font-size: 12px;
  }
}
