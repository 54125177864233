.SerachaBar-main-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}

.pets-select {
  outline: none;
  border: none;
}

.SerachaBar-main-div {
  position: absolute;
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
  height: 120px;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-shadow: 0px 34px 74px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  justify-content: center;
}

.sandha-kiya-huva {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 3rem;
}

.sandha-kiya-div {
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
  width: 100%;
}

.sandha-kiya-div-second {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.img-tarik-date {
  height: 30px;
  z-index: 10;
}

.Date-time-out {
  display: none !important;
}

.Add-span-datey {
  font-weight: 400;
  font-size: 14px;
  color: #8d8d8d;
  width: 140px;
}

.select {
  border: none !important;
  outline: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 1000 !important;
}

.Date-pikey-q {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.peaky-blainder {
  display: flex;
}

.section-thard-div {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  padding: 3rem;
}

form.example input[type="text"] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #f1f1f1;
}

/* Style the submit button */
form.example button {
  width: 45px;
  height: 45px;
  padding: 10px;
  background: black;
  color: white;
  font-size: 17px;
  background: #211b24;
  border-radius: 4px;

  cursor: pointer;
}

.local-and-goin-div {
  width: 25%;
}

/* Clear floats */
form.example::after {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 884px) {
  .SerachaBar-main-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .local-and-goin-div {
    width: 50%;
  }

  .section-thard-div {
    display: flex;
    flex-direction: column;
  }

  .sandha-kiya-huva {
    gap: 0rem;
  }

  .displya-none-ka {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .SerachaBar-main-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .local-and-goin-div {
    width: 50%;
  }

  .section-thard-div {
    display: flex;
    flex-direction: column;
  }

  .sandha-kiya-huva {
    gap: 0rem;
  }

  .displya-none-ka {
    display: none;
  }
}

@media screen and (max-width: 428px) {
  .SerachaBar-main-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .SerachaBar-main-section {
    height: 100%;
  }

  .section-thard-div {
    display: grid;
    gap: 2rem;

    justify-content: space-between;
  }

  .sandha-kiya-huva {
    display: flex;
    flex-direction: column;
  }

  .block-non-div {
    display: none;
  }

  .Add-span-datey {
    width: 190px;
  }

  .local-and-goin-div {
    width: 100%;
  }

  .sandha-kiya-huva {
    gap: 1rem;
    flex-direction: column;
    display: flex;
  }

  .sandha-kiya-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: baseline;
  }
}
