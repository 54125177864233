.Acoount-main-sec {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.Pone-p-input {
  position: relative;
}

.p-input {
  position: absolute;
  right: 11rem;
  border: none;
  outline: none;
}

.Ek-svg-hu-me {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.mastu-eroor {
  display: flex;
  align-items: flex-start;
  color: red;
  width: 90%;
}

.Acount-main-div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6rem;
  justify-content: center;
  margin: 3rem;
}

.Mobile-l-ogo {
  height: 25px;
  margin-left: 0.8rem;
}

.sedo-div-2 {
  position: absolute;
  right: 37.5rem !important;
  overflow: hidden;
}

.rentel-span {
  font-size: 14px;
  text-align: center;
  padding-left: 10px;
}

.input-field-white::placeholder {
  color: grey !important;
}

.span-div-p {
  width: 30%;
}

.the-span-2 {
  font-size: 40px;
}
.tams-and-cundition {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.safe-font {
  font-size: 14px;
  text-decoration: none !important;
  outline: none;
  border: none;
  color: black;
}
.i-t-a-added {
  /* width: 300px; */
  /* margin-left: 1rem; */
  border: none;
  outline: none;
}

.chake-boox-with {
  height: 20px;
  width: 20px;
}

.Dropdown-divv-select-country {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Drop-country-selact-tag {
  border: none;
  text-decoration: none;
  height: 30px;
  background-color: #ffffff;
  width: 76px;
  outline: none;
}

.dropdown-mobile-input {
  border: none;
  outline: none;
  width: 200px;
  background-color: #ffffff;
}

.optionclass-country {
  width: 1000px;
}

@media screen and (max-width: 1280px) {
  .p-input {
    right: 3rem;
  }

  .sedo-div-2 {
    right: 30rem;
    position: absolute;
  }

  .span-div-p {
    left: 17rem;
    top: 25rem;
  }
}

@media screen and (max-width: 1080px) {
  .p-input {
    right: 3rem;
  }

  .the-span-2 {
    font-size: 30px;
  }

  .span-div-p {
    left: 10rem;
    top: 21rem;
  }
}

@media screen and (max-width: 884px) {
  .Acount-main-div {
    display: flex;
    flex-direction: column;
  }

  .sedo-div-2 {
    right: 4rem !important;
    top: 3rem !important;
  }

  .p-input {
    right: 18rem;
  }

  .span-div-p {
    left: 12rem;
  }
}

@media screen and (max-width: 768px) {
  .Acount-main-div {
    display: flex;
    flex-direction: column;
  }

  .sedo-div-2 {
    right: 0.3rem !important;
    top: 3rem !important;
  }

  .p-input {
    right: 14rem;
  }

  .span-div-p {
    left: 12rem;
  }

  .main-chek {
    width: 94%;
  }
}

@media screen and (max-width: 428px) {
  .Acount-main-div {
    margin: 0rem;
    display: flex;
    flex-direction: column;
  }

  .girl-img-main {
    width: 100%;
    height: 100%;
  }

  .span-div-p {
    left: 2rem;
    top: 11rem;
  }

  .p-input {
    right: 3.4rem;
  }

  .sedo-div-2 {
    right: -1.4rem !important;
    position: absolute;
    top: -1rem !important;
  }

  .main-chek {
    width: 90%;
  }

  .Login-div-main {
    margin: 0rem;
  }

  .find-tha-span {
    font-weight: 500;
    font-size: 40px;
  }

  .f-g-btn-div {
    gap: 1rem;
  }

  .i-m-g-div {
    margin-bottom: 3rem;
  }

  .span-div-p {
    width: 280px;
  }

  .img-div-p {
    display: none;
  }

  .the-span-2 {
    display: none;
  }

  .girl-img-main {
    display: none;
  }

  .Mobile-l-ogo {
    display: none;
  }

  .Ek-svg-hu-me {
    width: 310px;
  }

  .mastu-eroor {
    width: 100%;
  }
}
