.PaymentPayouts-main-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.PaymentPayouts-main-div {
  width: 80%;
  display: flex;
  margin: 2rem;
  flex-direction: column;
}

.pymanbt-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.travel-span {
  font-weight: 700;
  font-size: 18px;
  color: #211b24;
}

.svg-matrhord {
  display: flex;
  gap: 1rem;
}

.mathor-div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 340px;
}

.mathord-line {
  width: 100%;
  height: 1px;
  border: 1px solid #e1e1e1;
  margin-top: 1rem;
}

.all-span-mathor {
  font-weight: 500;
  font-size: 17px;
  color: #211b24;
  cursor: pointer;
}

.travel-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
  cursor: pointer;
}

.pymanbt-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
  cursor: pointer;
}

.to-main-div {
  display: flex;

  gap: 5rem;
}

@media screen and (max-width: 884px) {
  .to-main-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .PaymentPayouts-main-div {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .to-main-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .mathord-line {
    width: 100%;
  }

  .mathor-div {
    width: 300px;
  }

  .PaymentPayouts-main-div {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .to-main-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .pymanbt-span {
    font-size: 24px;
  }

  .travel-span {
    font-size: 24px;
  }

  .mathor-div {
    width: 210px;
  }

  .PaymentPayouts-main-section {
    margin-top: 1rem;
  }

  .PaymentPayouts-main-div {
    margin: 1rem;
  }
}
