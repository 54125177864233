.date-picker-time-box {
  width: 300px;
}
.req-time-main-divv {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: aqua;     */
}
.btn-req-time-form {
  width: 200px;
  height: 40px;
}
.Btn-time-select {
  display: flex;
  justify-content: center;
}
.btn-req-time-form {
  background-color: #9c04f5;
  color: white;
  border-radius: 8px;
}
.req-time-spann-divv {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-picker-time-box {
  height: 35px;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  padding: 5px;
}
