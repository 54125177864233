.payment-main-section90 {
  width: 100%;
  height: 100%;
}
.payment-main-dib90 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  height: 100vh;
}
.Payment-Options-span {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}
.payment-input67 {
  border: 1px solid #e4e4e4;
  width: 420px;
  height: 60px;
  gap: 3rem;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0px 5px 0px 15px;
  margin-bottom: 2rem;
}
.dustbn-logo-imgg-src {
  width: 30px;
  height: 30px;
}
.jugad-input12 {
  width: 300px;
  border: none;
  outline: none;
}
.visaimg-img {
  width: 63px;
  height: 39px;
  padding-left: 0.5rem;
}
.jugad-input12::placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #1e2d38;
}
.paymnet-three-main-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.paymnet-add-div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Add-New-Card-span {
  font-size: 15px;
  font-weight: 500;
  color: #006ebd;
}
.add-logo-pls {
  width: 24px;
  height: 24px;
}
.add-logocard-div99 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.payment-span-divv-head {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Add-payment-btnn-div {
  height: 100px;
  display: flex;
  align-items: center;
}
.btn-pro-later-Pay {
  width: 100px;
  height: 40px;
  color: white;
  background-color: black;
  border-radius: 8px;
}
@media screen and (max-width: 884px) {
  .paymnet-three-main-div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }
  .paymnet-add-div {
    width: 100%;
  }
  .Payment-Options-span {
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) {
  .paymnet-three-main-div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .paymnet-add-div {
    width: 100%;
  }
  .Payment-Options-span {
    font-size: 23px;
  }
}
@media screen and (max-width: 428px) {
  .paymnet-three-main-div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }
  .paymnet-add-div {
    width: 100%;
  }
  .payment-input67 {
    width: 390px;
  }
  .Payment-Options-span {
    font-size: 23px;
  }
}
@media screen and (max-width: 390px) {
  .payment-input67 {
    width: 90%;
    gap: 10px;
  }
  .visaimg-img {
    width: 60px;
    height: 35px;
  }
}
@media screen and (max-width: 375px) {
  .visaimg-img {
    width: 50px;
    height: 30px;
  }
}
@media screen and (max-width: 360px) {
  .visaimg-img {
    width: 40px;
    height: 24px;
  }
}
@media screen and (max-width: 320px) {
  .card-span-divv {
    font-size: 12px;
  }
}
