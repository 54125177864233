.out-lat-main {
  display: flex;
}

.out-lat-second {
  display: flex;
  gap: 1rem;
  overflow: hidden;
}
.Near-You-span-taggg {
  font-weight: 600;
}
.backbtn {
  padding-bottom: 1rem;
  cursor: pointer;
}
.btn-property-del-main {
  background-color: #9c04f5;
  color: #ffffff;
  height: 35px;
  border-radius: 8px;
  width: 120px;
  font-size: 15px;
}
.btn-pro-Del-main {
  height: 35px;
  border-radius: 8px;
  width: 120px;
  font-size: 15px;
}

.My-profile-section {
  display: none;
}

.My-profile-section-22 {
  display: flex;

  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.My-profile-div {
  width: 80%;
  display: flex;

  flex-direction: column;
  margin: 2rem;
}

.Account-Settings-span {
  font-weight: 700;
  font-size: 18px;
  color: #211b24;
}

.svg-span-icon {
  width: 100%;
  gap: 1rem;
  display: flex;
  text-decoration: none;
  list-style: none;
}

.line-icon-div {
  width: 80%;
  border: 1px solid #dfdfdf;
  height: 1px;
  display: flex;
  justify-content: flex-end;
}

.claint-ke-buma-bum {
  z-index: 1;
  width: 50vh;
  background-color: white;
}

.e-x-tra {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.log-line-w {
  width: 1px;
  height: 100%;
  border: 1px solid #dfdfdf;
}

.main-div-profile {
  display: flex;
  width: 100%;

  margin-top: 0rem;
}

.all-saidebar-span {
  color: black;
  cursor: pointer;
  width: 200px;
  font-size: 15px;
  text-decoration: none;
  list-style: none;
}

/* .svg-span-icon.active > span {
  color: #9c04f5;
  border: none !important;
  text-decoration: none !important;
} */

.Home-spann-sec-para {
  text-decoration: none;
  color: black;
}
.span-clor-change-del {
  color: black;
}
.share-ppx-property {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.sharecoo-ppx-property {
  font-size: 16px;
  font-weight: 550;
  text-align: center;
  width: 90%;
}
.Property-p-cvv-added {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 885px) {
  .My-profile-section {
    width: 19%;
    display: block;
  }

  .My-profile-section-22 {
    display: none;
  }

  .line-icon-div {
    display: none;
  }

  .log-line-w {
    display: none;
  }
  .backbtn {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .line-icon-div {
    display: none;
  }

  .log-line-w {
    display: none;
  }

  .MyProperty-main-section {
    width: 55%;
  }

  .My-profile-section-22 {
    width: 40%;
  }

  .My-profile-div {
    margin: 2rem;
  }
}

@media screen and (max-width: 428px) {
  .My-profile-div {
    margin: 1.5rem;
  }

  .MyProperty-main-section {
    width: 80%;
  }

  .out-lat-second {
    display: flex;
  }
  .share-ppx-property {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
