.Swiper-main-imgg-home-divv-ppx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Swiper-main-imgg-main-divv {
  width: 100%;
  height: 100%;
}

.mySwiper-divv-main1 {
  width: 100%;
  position: relative;
  margin-top: 2rem;
  border: none;
}

.mySwipermain-divv-ppx {
  border-radius: 5px;
  width: 470px;
  height: 100%;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}

.Swiper-short-image-home-divv {
  width: 50%;
  margin-left: 4px;
}
.Slider-images-divv-ppx {
  width: 70px;
}
.Slider-images-iip {
  width: 80px;
}
.Swiper-short-image-home-divv {
  width: auto;
  height: auto;
}
.slideeeimg {
  border: 2px solid #cfcfcf;
  border-radius: 5px;
}

.Swiper-main-scroll-Divv {
  display: flex;
  justify-content: center;
}

.sliderdiv {
  width: 468px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.previmg > img {
  width: 100%;
  border-radius: 12px;
}

.left-swiper-btn {
  width: 46px;
  height: 42px;
  background-color: white;
  border: 1px solid #cfcfcf;
  border-radius: 40px;
}

.sipleimg {
  border-radius: 5px;
  opacity: 0.5 !important;
  display: block;
  width: 85%;
  height: 55%;
  object-fit: cover;
  background: #fffae2;
}

.previmg {
  width: 100%;
  height: 350px;
}

.previmg > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slideeeimg {
  width: 60px;
  height: 60px;
}

.overflowdiv {
  display: flex;
  gap: 2rem;
}

.swiper-slide-active {
}

.swiper-slide-visible {
  opacity: 1 !important;
}

@media screen and (max-width: 912px) {
  .sliderdiv {
    width: 360px;
    height: 100px;
  }

  .left-swiper-btn {
    width: 35px;
    height: 35px;
  }

  .sipleimg {
    width: 60px;
  }

  .slideeeimg {
    width: 45px;
  }
}

@media screen and (max-width: 884px) {
  .sliderdiv {
    width: 340px;
    height: 100px;
  }

  .left-swiper-btn {
    width: 35px;
    height: 35px;
  }

  .sipleimg {
    width: 60px;
  }

  .slideeeimg {
    width: 45px;
  }
}

@media screen and (max-width: 820px) {
  .Home-slider-merge-divv {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .slideeeimg {
    width: 60px;
  }
}

@media screen and (max-width: 320px) {
  .sliderdiv {
    width: 300px;
    height: 88px;
  }
}
