.Nav-bar-main-section {
  display: flex;
  width: 100%;
  background: #fffae2;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.ul-ke-bad-div > a.active {
  color: #9c04f5;
}

.Nav-bar-main-div {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
}

.divvvv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
  /* padding-left: 3rem; */
}

.homefooterlogo2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homefooterlogo2 > h3 {
  font-size: 14px;
  text-align: center;
  width: 100% !important;
  /* padding-left: 10px; */
}

.ham-burgur-nav {
  display: none;
}

.div-span-img-main {
  display: flex;
  gap: 10px;
  width: 100%;
}

.div-div-main-div {
  display: flex;
}

.Home-span-nav {
  font-weight: 700;
  font-size: 25px;
  color: #000000;
}

.Home-logo-img {
  height: 100px;
}

.nav-bar-2-span {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.Home-contect-span-1 {
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  list-style: none;
  color: #211b24;
  text-decoration: none;
}

.Home-contect {
  font-weight: 600;
  font-size: 18px;
  list-style: none;
  color: white;
  text-decoration: none;
}

.Home-contect-vac {
  font-weight: 600;
  font-size: 18px;

  list-style: none;
  color: #000000;
  text-decoration: none;
}

.Home-contect-span-1:hover {
  color: #9c04f5;
  text-decoration: none;
}

.Login-fust-buttan {
  height: 45px;
  width: 100px;
  background: #211b24;
  border-radius: 4px;
  color: white;
}

.Buttan-main-div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Become-a-Host-button {
  width: 167px;
  height: 45px;
  background: #9c04f5;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
}

.img-span-div-10 {
  gap: 0.5rem;
  display: flex;
}

.hemburgar-nav-bar-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fffae2;
}

.Home-contect-span-2 {
  font-weight: 600;
  font-size: 18px;
  list-style: none;
  color: #211b24;
}

.ul-ke-bad-div {
  display: flex;
  width: 315px;
  gap: 2rem;
}

.Font-div-four {
  font-weight: 600;
  font-size: 18px;
  list-style: none;
  color: #211b24;
  text-decoration: none;
}

.homefooterlogo2 > h2 {
  padding: 0px !important;
  margin: 0px !important;
}

@media screen and (max-width: 884px) {
  .Nav-bar-main-div {
    width: 90%;
    display: flex;
    align-items: baseline;
  }

  .img-span-div-2 {
    display: flex;
    gap: 5px;
  }

  .Nav-bar-main-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .Nav-bar-main-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .img-span-div-2 {
    display: flex;
    gap: 5px;
  }
}

@media screen and (max-width: 540px) {
  .Nav-bar-main-div {
    display: flex;
  }

  .div-span-img-main {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Home-span-nav {
    font-size: 34px;
  }

  .ham-burgur-nav {
    display: block;
  }

  .nav-bar-2-span {
    display: none;
  }

  .Buttan-main-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .div-span-img-main {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }

  .divvvv {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .ham-burgur-nav {
    display: block;
  }

  .nav-bar-2-span {
    display: none;
  }

  .Buttan-main-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ul-ke-bad-div {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .divvvv {
    display: contents !important;
  }

  .div-div-main-div {
    position: absolute;
    top: 8rem;
    left: 0rem;
    background: #fffae2;
    width: 100%;
  }

  .Home-contect-span-1 {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 428px) {
  .div-div-main-div {
    top: 10rem;
  }
}
