.FindByHosts-main-section {
    display: flex;
    width: 100%;


    height: 100%;
    align-items: center;
    justify-content: center;
}


.FindByHosts-main-div {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 2rem;
    flex-direction: column;
    justify-content: center;
}

.Find-by-Host-div {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Map-div-all {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
}

.drop-dwon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.main-div-by-span {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.property-Savannah-main-div {
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 10px;
    width: 270px;
    height: 276px;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Find-by-Host-span {
    font-weight: 700;
    font-size: 30px;
    color: #211B24;
}

.Nguyen-span-1 {
    font-weight: 700;
    font-size: 16px;
    color: #211B24;

}

.Savannah-div-3 {
    margin-top: 10px;
}

.Savannah-img-div {
    width: 128px;
    height: 128px;
}


.See-her-span {
    font-weight: 400;
    font-size: 13px;
    color: #211B24;
}

.btn-primary {
    background-color: white !important;
    color: black !important;
    background: #FFFFFF !important;
    border: 1px solid #E3E3E3 !important;
    border-radius: 5px !important;
}

.Become-a-butn {
    background: #211B24;
    border-radius: 4px;
    width: 167px;
    height: 45px;
    color: white;
    font-weight: 700;
    font-size: 15px;
}

@media screen and (max-width: 1280px) {
    .FindByHosts-main-div {
        width: 91%;
    }
}

@media screen and (max-width: 1115px) {
    .Map-div-all {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        width: 95%;
    }

    .main-div-by-span {
        width: 97%;
    }
}

@media screen and (max-width: 884px) {
    .Map-div-all {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        width: 90%;
    }

    .main-div-by-span {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .Map-div-all {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        width: 95%;
    }
}

@media screen and (max-width: 428px) {
    .Map-div-all {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        width: 85%;
    }

    .Find-by-Host-span {
        font-size: 20px;
    }
}

@media screen and (max-width: 360px) {
    .Map-div-all {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        width: 95%;
    }

    .Find-by-Host-span {
        font-size: 20px;
    }
}