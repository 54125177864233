.Download-our-App-section {
  display: flex;
  flex-direction: column;
  background-color: #fee24c;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.Download-our-App-main-div {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.countdown > p {
  font-size: 40px;
}

.countdown {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.Download-our-App-span {
  font-weight: 700;
  font-size: 60px;
}

.Download-to-span-div {
  width: 100%;
}

.lorem-span {
  width: 350px;
  margin-top: 1rem;
}

.img-main-div-ply {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.Google-play-img {
  width: 148.68px;
  height: 51.63px;
}

.App-store-img {
  width: 148.68px;
  height: 51.63px;
}

.iphone-img-main-div {
  display: flex;
}

.i-phone-img-1 {
  width: 194.31px;
  height: 436.45px;
  margin-top: 3rem;
}

.i-phone-img-2 {
  width: 190.31px;
  height: 452.67px;
}

@media screen and (max-width: 1115px) {
  .i-phone-img-2 {
    width: 145.31px;
    height: 352.67px;
  }

  .i-phone-img-1 {
    width: 144.31px;
    height: 336.45px;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 884px) {
  .Download-our-App-main-div {
    width: 92%;
  }

  .i-phone-img-1 {
    width: 164.31px;
    height: 376.45px;
    margin-top: 3rem;
  }

  .i-phone-img-2 {
    width: 160.31px;
    height: 372.67px;
  }

  .Download-our-App-main-div {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .Download-our-App-main-div {
    width: 100%;
  }

  .i-phone-img-1 {
    width: 164.31px;
    height: 376.45px;
    margin-top: 3rem;
  }

  .i-phone-img-2 {
    width: 160.31px;
    height: 372.67px;
  }
}

@media screen and (max-width: 428px) {
  .Download-our-App-section {
    height: 100%;
  }

  .countdown > p {
    font-size: 30px;
  }

  .i-phone-img-2 {
    width: 140.31px;
    height: 342.67px;
  }

  .i-phone-img-1 {
    width: 154.31px;
    height: 336.45px;
    margin-top: 3rem;
  }

  .iphone-img-main-div {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .Download-our-App-span {
    font-weight: 700;
    font-size: 40px;
  }

  .Download-to-span-div {
    /* width: 200px; */
    display: flex;
    flex-direction: column;
  }

  .Google-play-img {
    width: 128.68px;
    height: 41.63px;
  }

  .App-store-img {
    width: 128.68px;
    height: 41.63px;
  }

  .Download-our-App-div {
    width: 90%;
  }
}

@media screen and (max-width: 391px) {
  .Download-our-App-span {
    font-weight: 600;
    font-size: 40px;
  }

  .Download-our-App-div {
    width: 90%;
  }

  .lorem-span {
    width: 325px;
  }

  .iphone-img-main-div {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 376px) {
  .Download-our-App-span {
    font-weight: 700;
    font-size: 40px;
  }

  .Download-our-App-div {
    width: 90%;
  }

  .lorem-span {
    width: 325px;
  }

  .iphone-img-main-div {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 360px) {
  .Download-our-App-span {
    font-weight: 600;
    font-size: 40px;
  }

  .Download-our-App-div {
    width: 90%;
  }

  .lorem-span {
    width: 325px;
  }
}
