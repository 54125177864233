.Gov-id-ver-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.Gov-driv-sec-divv {
  width: 100%;
}
.Gov-driv-lic-pass-main {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.iden-span-tagg {
  font-size: 35px;
  font-weight: 550;
}
.Gov-id-ver-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.Identity-span-divv {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Gov-id-spann-ppx-tag {
  font-size: 20px;
}
.File-img-gov {
  display: none;
}
.Tap-to-imgg {
  width: 300px;
  object-fit: cover;
  height: 180px;
  border: 0.5px solid lightgray;
  border-style: dashed;
  border-radius: 8px;
  cursor: pointer;
}
.Default-to-imgg {
  width: 300px;
  object-fit: cover;
  height: 180px;
  border: 0.5px solid lightgray;
  border-style: dashed;
  border-radius: 8px;
  cursor: pointer;
}
.Gov-driv-lic-pas-divv-ppx {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
}
.btn-identity-gov-divv {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Btn-save-Identity {
  width: 300px;
  height: 40px;
  background-color: black;
  color: white;
  border-radius: 8px;
  margin-bottom: 2rem;
}
.Dropdown-gov-id-divv {
  height: 30px;
  width: 250px;
  border-radius: 6px;
}
.resend-email-divv-ppx {
  width: 100%;
  display: flex;
  justify-content: center;
}
.resend-email-tagg-ppx {
  color: #9c04f5;
  font-weight: 600;
}
