.HowItWorks-main-section {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fee24c;
  display: none;
}

.HowItWorks-main-div {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
}

.How-it-Works-div-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
}

.How-it-Works-span {
  font-weight: 700;
  font-size: 30px;
}

.houseimg-img-1 {
  width: 83px;
  height: 83px;
}

.Duis-main-div {
  margin-top: 2rem;
}

.card-main-div-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  margin-top: 2rem;
}

.Vestibulum-span {
  font-weight: 500;
  font-size: 14px;
}

.Duis-efficitur-span {
  font-weight: 600;
  font-size: 20px;
  color: #211b24;
}

.Vivamus-et-consequat-span {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  color: #838383;
}

.alll-mainn-divv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Get-Started-butn {
  background: #211b24;
  border-radius: 4px;
  width: 144px;
  height: 45px;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border: none;
  outline: none;
}

.How-it-Works-div-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  margin-top: 3rem;
}

@media screen and (max-width: 884px) {
  .alll-mainn-divv {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .alll-mainn-divv {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media screen and (max-width: 428px) {
  .alll-mainn-divv {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
