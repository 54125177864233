.SelectDates-main-sec {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

}

.SelectDates-main-div {
    width: 80%;
    display: flex;
    margin: 2rem;
    flex-direction: column;
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: none !important;
}

.selt-span {
    font-weight: 600;
    font-size: 24px;

    color: #211B24;
}

.Ap-kon-ho {
    display: flex;
    gap: 1rem;
}

.react-datepicker {
    border: none !important;
}



.ma-hu-selact-deta {
    display: flex;
    gap: 5rem;
}

.selact-deta-div {
    display: flex;

}

.Save-Pay-btn {
    background: #9C04F5;
    border-radius: 4px;
    color: white;
    border: none;
    width: 200px;
    height: 45px;
    margin-top: 2rem;
    outline: none;
}

.Add-People-span {
    font-weight: 600;
    font-size: 24px;
    color: #211B24;

}

.Mainas-btn {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    width: 38px;
    height: 38px;
    border-radius: 50px;

}

.caunt-div {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.Adults-span {
    font-weight: 500;
    font-size: 14px;
    color: #211B24;
}

.Main-Adults-div {
    display: flex;
    gap: 4rem;
}

.Child-Age-span {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.input-age {
    width: 133px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    outline: none;
    padding: 1rem;
}

.man-age-div {
    display: flex;
    gap: 4rem;

}

.cildran-t--span {
    font-weight: 500;
    font-size: 14px;
    color: #211B24;
}

.ma-to-esa-he {
    display: flex;
    gap: 4rem;
}


@media screen and (max-width: 884px) {
    .ma-hu-selact-deta {
        display: flex;
        flex-direction: column;
        align-items: center;

    }
}

@media screen and (max-width: 768px) {
    .ma-hu-selact-deta {
        display: flex;
        flex-direction: column;
        align-items: center;

    }
}

@media screen and (max-width: 428px) {
    .ma-hu-selact-deta {
        display: flex;
        flex-direction: column;

        gap: 2rem;
    }

    .caunt-div {
        gap: 1rem;
    }

    .Main-Adults-div {
        gap: 5rem;
    }

    .man-age-div {
        gap: 3rem;
    }
}

@media screen and (max-width: 360px) {
    .ma-hu-selact-deta {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .caunt-div {
        gap: 1rem;
    }

    .Main-Adults-div {
        gap: 3rem;
    }

    .man-age-div {
        gap: 3rem;
    }
}