.AddProperty-main-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Ma-svg-top {
  margin-bottom: 4px;
}
.checkdivmain {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 280px;
}
.HouseRules-chake-box {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 900px;
  margin-bottom: 0.5rem;
}
.between-time {
  display: flex;
  align-items: center;
  gap: 11rem;
}
.noise-time {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 11rem;
}
.Swimming-time {
  display: flex;
  align-items: center;
  gap: 5.5rem;
  margin-top: 1rem;
}
.tub-time {
  display: flex;
  align-items: center;
  gap: 8.8rem;
  margin-top: 1rem;
}
.out-time {
  display: flex;
  align-items: center;
  gap: 10rem;
  margin-top: 1rem;
}

.Specify-input-span {
  display: flex;
  gap: 4rem;
}
.towels-apn-in {
  width: 250px;
  height: 40px;
  outline: none;
  padding: 1rem;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
}
.react-time-picker__wrapper {
  background: #f7f7f7 !important;
  border-radius: 4px !important;
  border: 1px solid #d1d1d1 !important;
}
.react-time-picker__inputGroup__input {
  outline: none !important;
}
.othars-input-txt {
  width: 250px;
  height: 40px;
  outline: none;
  padding: 1rem;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
}
.HouseRules-chake-box > input {
  width: 25px;
}
.checkboxparent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: auto;
}
.parentkaparent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 1rem;
}
.checkboxparent > :nth-child(1) {
  font-weight: bold;
}
.checkdivmain > input {
  width: 25px;
}
.form-errror {
  color: red;
}
.eroor-input {
  display: flex;
  flex-direction: column;
}
.AddProperty-main-div {
  width: 80%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  margin: 3rem;
}
.Add-Property-div {
  display: flex;
  width: 100%;
}
.add-propaty-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}
.Basic-Details-span {
  font-weight: 700;
  font-size: 18px;
  color: #211b24;
}
.labul-input {
  display: flex;
  flex-direction: column;
}
.main-input-div {
  display: grid;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
.input-khud {
  width: 350px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
}
.lebal-potta {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
}
.redio-input {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.Cancellation-radio {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.ht-ml-radio {
  width: 100%;
}
.opan-div {
  width: 330px;
  height: 39px;
  border: none;
  outline: none;
  padding-left: 1rem;
  background-color: #ffffff;
}
.span-selact-rtag {
  display: flex;
  flex-direction: column;
}
.city-section {
  width: 350px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
}
.opan-div > option {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #211b24 !important;
}
.city-select-section {
  width: 350px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Maximum-selact-main {
  width: 350px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
}
.city-select-thard-section {
  width: 350px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
}
.All_item_main {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
}
.Add_more_btn_beds_bathrooms {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.city-section-fast {
  width: 370px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
}
.mal-te-img {
  width: 30px;
  height: 30px;
}
.photo-add-span {
  font-weight: 700;
  font-size: 18px;
  color: #211b24;
}
.add-delet-option {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}
.add-photo-span {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
}
.Ht-ml-for {
  background: #fdfaff;
  border: 1px solid #9c04f5;
  border-radius: 4.83099px;
  width: 79.71px;
  height: 79.71px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sab-img-ka-div {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.add-kiya-huva-pic {
  width: 79.71px;
  height: 79.71px;
  border-radius: 4.83099px;
  object-fit: cover;
}
.Btn-dada-delet {
  position: absolute;
  right: -11px;
  border: none;
  border-radius: 40px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #8a8a8a;
}
.Malte-pal-cls {
  display: none;
}
.Add_more_main_divv {
  display: flex;
  /* gap: 1rem;  */
  flex-direction: column;
  align-items: flex-start;
}
.button_plus_divv {
  display: flex;
  gap: 0.5rem;
}
.Add_expertise_btnn {
  text-decoration: none;
  background-color: #fff;
  color: #9c04f5;
  padding: 0;
}
.Del_expertise_btnn {
  background-color: #fff;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .main-input-div-selact {
  display: grid;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
} */
.main-input-div-selact {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.Maximum-div-selact-tag {
  width: 330px;
  height: 39px;
  border: none;
  outline: none;
  padding-left: 1rem;
  background-color: #ffffff;
}
.Maximum-div-selact-tag-dusra {
  width: 330px;
  height: 39px;
  border: none;
  outline: none;
  padding-left: 1rem;
  background-color: #fff;
}
.opan-div-selact-tag {
  width: 95%;
  height: 39px;
  border: none;
  outline: none;
  padding-left: 1rem;
  background-color: #ffffff;
}
.opan-div-fast {
  width: 355px;
  height: 39px;
  border: none;
  outline: none;
  padding-left: 1rem;
}
.text-area-seconfd {
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  outline: none;
}
.Write-here-span {
  display: flex;
  width: 100%;
  margin-top: 1rem;
}
.Amenities-div {
  display: flex;
  flex-direction: column;
}
.Amenities-spann-tagg-pee {
  font-size: 18px;
  font-weight: 600;
}
.mapmathard {
  display: flex;
  flex-direction: column;
}
.hawy-drayvor {
  margin-top: 3rem;
}
.pluse {
  width: 25px;
}
.plush-and-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.flex-dada-khud {
  display: flex;
  margin-bottom: 1rem;
}
.input-khud-dusribar {
  width: 88%;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
}
.doller-sghin {
  display: flex;
  align-items: center;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  height: 45px;
  outline: none;
}
.input-khud-thard {
  background: #ffffff;
  border: none;
  outline: none;
  content: "$";
}
.Amenities-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.input-ka-input-king {
  display: flex;
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.redio-input > input[type="radio"] {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
.addressdropdown > span {
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.238);
  padding-bottom: 5px;
}
.Add-Propertybtn-seco {
  background: #9c04f5;
  border-radius: 4px;
  color: white;
  border: none;
  outline: none;
  width: 370px;
  height: 45px;
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
}
.add-prop-btn-div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
.chack-in-span {
  width: 240px;
}
.btnn-divv-class {
  font-size: 18px;
  font-weight: 600;
}
.Payment-ppx-cvv {
  display: flex;
  width: 64%;
  justify-content: center;
  height: 46px;
}
.spandivv-ppx-pay {
  font-size: 16px;
  font-weight: 600;
}
.Button-divv-pay {
  display: flex;
  width: 45%;
  justify-content: center;
  height: 50px;
  gap: 18px;
}
.fixed-button {
  position: absolute;
  top: 200px;
  right: 60px;
  z-index: 999;
  padding: 10px;
  background-color: #9c04f5;
  color: #fff;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: opacity 0.3s;
}
.fixed-button.fixed {
  position: fixed;
  opacity: 1;
}
.a-link-tagg-btnn {
  text-decoration: none;
}

.Spann-checkboxx-sett {
  width: 800px;
}

.Maximum-Accommodate-main-div {
  display: flex;
  gap: 4rem;
  margin-top: 2rem;
}

.cleaning-services-Free-in-le {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.cleaning-services-Free-in-le > input {
  width: 25px;
}
.Facilities-all {
  font-weight: bold;
}
.overflowing-add-immgg {
  overflow-x: scroll;
  display: flex;
  gap: 20px;
  width: 400px;
}
.overflowing-add-immgg::-webkit-scrollbar {
  display: none;
}
.autocomplete-dropdown-container {
  width: 360px;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  /* top: 366px; */
}
.Select_merge_divv {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media screen and (max-width: 1280px) {
  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
  }
  .parentkaparent {
    grid-template-columns: repeat(3, 1fr);
  }
  .Spann-checkboxx-sett {
    width: 825px;
  }
  .cleaning-services-Free-in-le {
    width: 85%;
  }
  .main-input-div {
    gap: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .input-khud {
    width: 285px;
  }
  .opan-div {
    width: 305px;
  }
  .city-select-section {
    width: 285px;
  }
  .cleaning-services-Free-in-le {
    width: 100%;
  }
}
@media screen and (max-width: 914px) {
  .HouseRules-chake-box {
    width: 820px;
  }
  .checkdivmain {
    width: 257px;
  }
  .AddProperty-main-div {
    width: 90%;
  }
}
@media screen and (max-width: 885px) {
  .main-input-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: baseline;
  }
  .overflowing-add-immgg {
    overflow-x: scroll;
  }
  .parentkaparent {
    grid-template-columns: repeat(2, 1fr);
  }
  .city-select-section {
    width: 320px;
  }
  .input-khud {
    width: 320px;
  }
  .city-section-fast {
    width: 320px;
  }
  .city-select-thard-section {
    width: 320px;
  }
  .doller-sghin {
    width: 320px;
  }
  .opan-div-selact-tag {
    width: 305px;
  }
  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-content: center;
    align-items: baseline;
  }
  .hawy-drayvor {
    display: flex;
    flex-direction: column;
  }
  .HouseRules-chake-box {
    width: 700px;
  }
  .cleaning-services-Free-in-le {
    display: flex;
    width: 66%;
    justify-content: space-between;
  }
  .cleaning-services-Free-in-le > input {
    width: 25px;
  }
  .Spann-checkboxx-sett {
    width: 100%;
  }
  .Maximum-div-selact-tag {
    width: 305px;
  }
  .Maximum-Accommodate-main-div {
    gap: 6rem;
    margin-top: 2rem;
  }
  .Maximum-selact-main {
    width: 320px;
  }
  .Maximum-div-selact-tag-dusra {
    width: 305px;
  }
}
@media screen and (max-width: 835px) {
  .Maximum-Accommodate-main-div {
    gap: 4.5rem;
  }
  .cleaning-services-Free-in-le {
    width: 70%;
  }
}
@media screen and (max-width: 820px) {
  .main-input-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: baseline;
  }
  .opan-div {
    width: 330px;
  }
  .checkdivmain {
    width: 300px;
  }
  .overflowing-add-immgg {
    overflow-x: scroll;
  }
  .city-select-section {
    width: 340px;
  }
  .input-khud {
    width: 340px;
  }
  .city-section-fast {
    width: 300px;
  }
  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    justify-content: center;

    align-items: center;
  }
  .hawy-drayvor {
    display: flex;
    flex-direction: column;
  }
  .HouseRules-chake-box {
    width: 680px;
  }
  .cleaning-services-Free-in-le {
    display: flex;
    width: 83%;
    justify-content: space-between;
  }
  .cleaning-services-Free-in-le > input {
    width: 25px;
  }
}
@media screen and (max-width: 800px) {
  .cleaning-services-Free-in-le {
    width: 86%;
  }
  .cleaning-services-Free-in-le > input {
    width: 30 px;
  }
}
@media screen and (max-width: 768px) {
  .main-input-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: baseline;
  }

  .opan-div {
    width: 300px;
  }
  .checkdivmain {
    width: 300px;
  }
  .overflowing-add-immgg {
    overflow-x: scroll;
  }
  .city-select-section {
    width: 320px;
  }

  .input-khud {
    width: 320px;
  }

  .city-section-fast {
    width: 300px;
  }

  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    justify-content: center;

    align-items: center;
  }
  .hawy-drayvor {
    display: flex;
    flex-direction: column;
  }

  .HouseRules-chake-box {
    width: 650px;
  }

  .cleaning-services-Free-in-le {
    display: flex;
    width: 89%;
    justify-content: space-between;
  }

  .cleaning-services-Free-in-le > input {
    width: 25px;
  }
  .Maximum-Accommodate-main-div {
    gap: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .parentkaparent {
    grid-template-columns: repeat(1, 1fr);
  }

  .checkboxparent {
    width: 100%;
  }

  .overflowing-add-immgg {
    overflow-x: scroll;
  }

  .HouseRules-chake-box {
    width: 450px;
  }

  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: baseline;
  }
  .main-input-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    justify-content: center;

    align-items: baseline;
  }
  .city-select-section {
    width: 320px;
  }
  .opan-div {
    width: 420px;
  }
  .Specify-input-span {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .Maximum-Accommodate-main-div {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 428px) {
  .Maximum-Accommodate-main-div {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 320px;
  }
  .Maximum-div-selact-tag {
    width: 305px;
  }
  .main-input-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    justify-content: center;
  }
  .overflowing-add-immgg {
    overflow-x: scroll !important;
    display: flex !important;
    width: 100%;
  }
  .Ht-ml-for {
    width: 108px;
  }

  .parentkaparent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
  }
  .input-khud {
    width: 320px;
  }
  .city-section-fast {
    width: 345px;
  }
  .city-select-section {
    width: 320px;
  }
  .hawy-drayvor {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
  .input-khud-dusribar {
    width: 85%;
  }

  .Add-Property-div {
    margin-bottom: 1rem;
  }

  .opan-div-selact-tag {
    width: 305px;
  }

  .opan-div-fast {
    width: 330px;
  }

  .opan-div {
    width: 305px;
  }

  .mapmathard {
    display: flex;
  }

  .between-time {
    display: flex;
    align-items: center;
    gap: 7.5rem;
  }

  .night-gold {
    width: 100%;
  }

  .Check-out-all {
    width: 131px;
  }

  .out-time {
    display: flex;
    align-items: center;
    gap: 5rem;
  }

  .noise-time {
    display: flex;
    align-items: center;
    gap: 5rem;
  }

  .Swimming-time {
    display: flex;
    align-items: center;
    gap: 5rem;
  }
  .night-span {
    width: 100%;
  }
  .night-span-tagg {
    color: #211b24;
    font-size: 17px;
  }
  .tub-time {
    display: flex;
    align-items: center;
    gap: 5rem;
    margin-bottom: 2rem;
  }
  .HouseRules-chake-box {
    width: 330px;
  }
  .cleaning-services-Free-in-le {
    display: flex;
    width: 87%;
    justify-content: space-between;
  }
  .cleaning-services-Free-in-le > input {
    width: 24px;
  }
  .checkdivmain {
    width: 335px;
  }
  .Maximum-div-selact-tag-dusra {
    width: 305px;
  }
}
@media screen and (max-width: 415px) {
  .cleaning-services-Free-in-le {
    display: flex;
    width: 90%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 390px) {
  .main-input-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    justify-content: center;
  }
  .main-input-div-selact {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
  }
  .overflowing-add-immgg {
    overflow-x: scroll;
  }
  .city-section-fast {
    width: 320px;
  }
  .city-select-section {
    width: 320px;
  }
  .hawy-drayvor {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    justify-content: center;
  }
  .input-khud-dusribar {
    width: 85%;
  }
  .Add-Property-div {
    margin-bottom: 1rem;
  }
  .Maximum-Accommodate-main-diva {
    display: flex;
    flex-direction: column;
  }
  .cleaning-services-Free-in-le {
    display: flex;
    width: 95%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 376px) {
  .cleaning-services-Free-in-le {
    display: flex;
    width: 96%;
    justify-content: space-between;
  }
  .cleaning-services-Free-in-le > input {
    width: 26px;
  }
}
@media screen and (max-width: 320px) {
  .checkboxparent {
    width: 84%;
  }
  .checkdivmain {
    width: 243px;
  }
  .cleaning-services-Free-in-le {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .cleaning-services-Free-in-le > input {
    width: 25px;
  }
  .AddProperty-main-section {
    display: block;
    width: 90%;
  }
  .opan-div-selact-tag {
    width: 258px;
  }
  .city-select-section {
    width: 257px;
  }
  .Amenities-spann-tagg-pee {
    margin-left: 29px;
  }
  .between-time {
    gap: 3rem;
  }
  .out-time {
    gap: 0.5rem;
  }
  .noise-time {
    gap: 0.5rem;
  }
  .Swimming-time {
    gap: 0.5rem;
  }
  .tub-time {
    gap: 0.5rem;
  }
  .HouseRules-chake-box {
    width: 277px;
  }
  .cleaning-services-Free-in-le {
    display: flex;
    width: 243px;
    justify-content: space-between;
  }
}
