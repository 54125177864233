.Choose-sec-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: #FFFAE2;
    width: 100%;
}

.Choose-sec-div {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 2rem;
    flex-direction: column;
    justify-content: center;
}

.Choose-thard {
    width: 80%;
    display: flex;

    margin: 2rem;
    flex-direction: column;
    justify-content: center;
}