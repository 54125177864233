.wrong-btn {
    background: #9C04F5;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
    width: 254px;
    height: 45px;
    font-weight: 700;
    font-size: 15px;
}

.failed-span {
    font-weight: 700;
    font-size: 18px;
    color: #211B24;
}

.like-span {
    font-weight: 400;
    font-size: 14px;
    color: #211B24;
}