.chat-main-sedction {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.chat-main-div {
  width: 80%;
  display: flex;
  margin: 2rem;
  flex-direction: column;
  background: #ffffff;
}

.chat-ke-border {
  border: 1px solid #e1e1e1;
  border-radius: 6px;

  height: 90vh;
}

.chat-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.Chat-seracha {
  width: 333px;
  height: 30px;
  border: none;
  outline: none;
}

.serach-chat-girl-div {
  display: flex;
  align-items: center;
}

.seracha-svg-cha-vale {
  width: 40%;
  padding: 1rem;
}

.CHat-girl-1 {
  width: 35px;
  height: 35px;
}

.chat-extra {
  height: 0.5px;
  width: 100%;
  background: #e1e1e1;
}

.senter-div-line {
  height: 70px;
  width: 0.5px;

  background: #e1e1e1;
}

.Chat-ho-rhe-ha {
  width: 35px;
}

.img-Darrell-div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.Steward-span {
  font-weight: 700;
  font-size: 15px;
  color: #232b37;
}

.chat-2-girl {
  width: 64px;
  height: 64px;
}

.img-user-div {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: column;
}

.upar-nicha {
  display: flex;
  flex-direction: column;
}

.user-flex {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.user-kaliya-msg {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  flex-direction: row-reverse;
}

.img-time-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-all-div {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 490px;
}

.msg-div {
  display: flex;
  gap: 0.5rem;
  background: #ececec;
  border-radius: 7px;
  max-width: max-content;
  height: 40px;
  align-items: center;
  padding: 1rem;

  justify-content: center;
}

.user-line {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 500px;
  width: 700px;
  overflow: scroll;
}

.start-line {
  height: 100px;
  width: 0.5px;
  background: #e1e1e1;
}

.chat-kar-na-input {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 357px;
  height: 40px;
  outline: none;
  padding: 1rem;
}

.sand-btn {
  background: white;
}

.input-chat-sand {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.line-img-user {
  width: 500px;
  border: 1px solid #d1d1d1;
}

.line-ka-liya-khan {
  display: flex;
  gap: 1rem;
}
