.Property-ppx-pop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
}
.Property-card-divv-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-pro-later-popup {
  background-color: black;
  color: white;
  width: 60px;
  height: 40px;
  border-radius: 12px;
}
.spandivv-ppx-property-ook {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.Work_connectors_logo_blue {
  height: 120px;
}
.btn-okk-later-popup {
  height: 40px;
  border-radius: 8px;
  width: 60px;
  background-color: #9c04f5;
  color: white;
}
