.Search-live-main-div {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.SecNarYou-div-main {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  gap: 2rem;
  justify-content: center;
}

.search-div-live-ka-bad {
  outline: none;
  width: 280px;
  height: 30px;
  border: none;
  padding: 1rem;
}

.Search-live-ke-bad-btn {
  background-color: black;
  color: white;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
}

.svg-search-div-live-ka-bad {
  outline: none;
  width: 280px;
  height: 30px;
  border: none;
}

.Check-in-svg {
  margin-left: 0.5rem;
}

.input-khud-serach-ka {
  width: 343px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
}

.adres-and-kya {
  width: 343px;
  height: 100%;
}
.svg-p-div {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
@media screen and (max-width: 884px) {
  .Search-live-main-div {
    height: 65vh;
  }
}
@media screen and (max-width: 428px) {
  .Search-live-main-div {
    height: 80vh;
  }
}
@media screen and (max-width: 428px) {
  .Search-live-main-div {
    height: 85vh;
  }
}
@media screen and (max-width: 390px) {
  .Search-live-main-div {
    height: 90vh;
  }
}
