.Reporting-main-section {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Reporting-main-div {
    width: 80%;
    display: flex;

    margin: 2rem;
    gap: 2rem;
    flex-direction: column;
}

.Reporting-Listing-div {
    display: flex;
    flex-direction: column;
}

.report-radio {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.Save-btn-aya {
    width: 157px;
    height: 45px;
    background: #211b24;
    border-radius: 4px;
    color: white;
    font-weight: 700;
    font-size: 15px;
    border: none;
    margin-top: 1rem;
}