.AfterLogin-sec {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.AfterLogin-main-div {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  margin: 2rem;
}
.mainnn-divvv-svg {
  width: 300px;
  height: 100%;
  /* background-color: #d1d1d1; */
}
.Read-less-span {
  color: #d1d1d1;
}
.drop-down-div {
  display: flex;
  align-items: center;
  background: #211b24;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 98px;
  height: 45px;
  justify-content: space-evenly;
}
.dropdown-toggle::after {
  display: none !important;
}
.btn-primary {
  border: none !important;
}
.wimndo-img {
  width: 1215px;
  height: 300px;
}
.drop-svg-main {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}
.pozison-ko-handel {
  position: relative;
}
.oppo-phone {
  position: absolute;
  top: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bangloz-img-11 {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.carddivmain {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  display: flex;
  flex-wrap: wrap;
  place-items: center;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  padding-bottom: 2rem;
}
.nearyou {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  width: 82%;
}
.nearyou > span {
  font-size: 30px;
}
.dropdown-item:active:hover {
  color: white !important;
  background-color: gray !important;
  gap: 1rem !important;
}
.filter-btn {
  border: none;
  background-color: white;
}
.Long-sedo-img {
  width: 1215px;
  height: 300px;
}
.aftar-login-div {
  position: absolute;
  top: 0;
}
.placeee-divvv {
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
}
.perfect-place-span {
  font-weight: 700;
  font-size: 29px;
  color: #211b24;
}
.span-div-place {
  width: 300px;
}
.loding-rolar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}
.loding-rolar > * {
  color: #9c04f5 !important;
}
.Phone-number-btnn-divv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.save-btn-phone-code {
  width: 100px;
  height: 40px;
  color: #fff;
  background-color: black;
  border-radius: 4px;
}
.input-and-country-merge {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Know-More-btn {
  background: #9c04f5;
  border-radius: 4px;
  border: none;
  color: white;
  width: 110px;
  height: 35px;
}
.star-svgg-main-divv-ppx {
  position: relative;
}
.svg-divv-avg {
  font-size: 14px;
}
.svg-span-div-main-span {
  position: absolute;
  top: 25px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fee24c;
  width: 45px;
  height: 22px;
  gap: 2px;
  border-radius: 4px;
}
@media screen and (max-width: 1280px) {
  .wimndo-img {
    width: 1200px;
    height: 300px;
  }
  .Long-sedo-img {
    width: 1200px;
    height: 300px;
  }
  .AfterLogin-main-div {
    width: 94%;
  }
  .nearyou {
    width: 97%;
  }
}
@media screen and (max-width: 1115px) {
  .wimndo-img {
    width: 1000px;
    height: 300px;
  }
  .mainnn-divvv-svg {
    width: 100%;
  }
  .Long-sedo-img {
    width: 1000px;
    height: 300px;
  }
  .AfterLogin-main-div {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .AfterLogin-main-div {
    display: flex;
    width: 93%;
    gap: 1rem;
    justify-content: center;
    flex-direction: column;
    margin: 2rem;
  }
  .Long-sedo-img {
    width: 960px;
  }
  .wimndo-img {
    width: 960px;
  }
}
@media screen and (max-width: 912px) {
  .wimndo-img {
    width: 845px;
    height: 300px;
  }
  .Long-sedo-img {
    width: 845px;
    height: 300px;
  }
  .bangloz-img-11 {
    width: 236px;
  }
}
@media screen and (max-width: 884px) {
  .wimndo-img {
    width: 750px;
    height: 300px;
  }
  .Long-sedo-img {
    width: 750px;
    height: 300px;
  }
  .AfterLogin-main-div {
    /* width: 90%; */
    width: 85%;
  }
  .bangloz-img-11 {
    width: 310px !important;
  }
  /* .carddivmain {
    grid-template-columns: repeat(2, 1fr);
  } */
  .nearyou {
    width: 87%;
  }
}
@media screen and (max-width: 768px) {
  .wimndo-img {
    width: 700px;
    height: 300px;
  }
  .Long-sedo-img {
    width: 700px;
    height: 300px;
  }
  .AfterLogin-main-div {
    width: 90%;
  }
  .bangloz-img-11 {
    width: 310px !important;
  }
  .svg-span-div-main-span {
    left: 15.5rem !important;
  }
  .nearyou {
    width: 94%;
  }
}

@media screen and (max-width: 540px) {
  .drop-svg-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .AfterLogin-main-div {
    width: 89%;
  }
  .Long-sedo-img {
    position: absolute;
  }
  .wimndo-img {
    width: 380px;
    height: 200px;
  }
  .Long-sedo-img {
    width: 380px;
    height: 200px;
  }
  .perfect-place-span {
    font-size: 20px;
  }
  .placeee-divvv {
    margin-left: 1rem;
    top: 15rem;
    width: 150px;
  }
  .span-div-place {
    margin-left: 1rem;
    top: 19.3rem;
  }
  .Know-More-div {
    margin-left: 1rem;
    top: 23rem;
  }
  .Aftar-login-img {
    position: relative;
  }
  .aftar-login-div {
    position: absolute;
    top: 0;
  }
  .oppo-phone {
    padding: 0.5rem;
  }
  .svg-p-div {
    width: 310px;
  }
  .Know-More-btn {
    font-size: 14px;
  }
  .Password-ek-or {
    display: flex;
    width: 87%;
  }
}
@media screen and (max-width: 415px) {
  .carddivmain {
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 1rem;
  }
  .drop-svg-main {
    gap: 1rem;
  }
}

@media screen and (max-width: 394px) {
  .carddivmain {
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 1rem;
  }
  .drop-svg-main {
    gap: 1rem;
  }
  .Long-sedo-img {
    width: 350px;
  }
  .bangloz-img-11 {
    width: 290px !important;
  }
  .wimndo-img {
    width: 350px;
  }
  .svg-p-div {
    width: 347px;
  }
}
@media screen and (max-width: 376px) {
  .AfterLogin-main-div {
    width: 92% !important;
  }
}
@media screen and (max-width: 360px) {
  .carddivmain {
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 1rem;
  }
  .drop-svg-main {
    gap: 1rem;
  }
  .Long-sedo-img {
    width: 320px;
  }
  .bangloz-img-11 {
    width: 290px !important;
  }
  .wimndo-img {
    width: 320px;
  }
  .svg-p-div {
    width: 180px;
  }
  .Password-ek-or {
    display: flex;
    width: 94%;
  }
}
