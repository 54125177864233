.NearYou-main-section {
  display: flex;
  width: 100%;
  background-color: black;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}

.margin-dena-ka-liya {
  margin-top: 6rem;
}

.NearYou-main-div {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
  justify-content: center;
}

.bangloz-img-1 {
  width: 400px;
}

.mainnn-divvv-svg {
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 0.8rem;
}

.svg-span-div-main {
  background: #fee24c;
  border-radius: 3px;
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  gap: 3px;
  text-align: center;
  padding: 5px;
  position: absolute;
  top: 1.7rem;
  left: 23rem;
}

.Grandview-span-1 {
  font-weight: 500;
  font-size: 18px;
  color: #211b24;
}

.Accessible-span {
  font-weight: 500;
  font-size: 15px;
  color: #838383;
}

.ronny-p {
  border-radius: 8px;
}

.night-span {
  color: #211b24;
  font-weight: 600;
  font-size: 17px;
}

.night-span-2 {
  color: #838383;
  font-weight: 400;
  font-size: 15px;
}

.span-naghit-div-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none;
}

.Near-You-img-div-span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 1rem;
}

.laft-raghit-img-div {
  display: flex;
  gap: 1rem;
}

.Laft-img {
  width: 50px;
}

.raghit-img {
  width: 50px;
}

.Right-img-div {
  border-radius: 60%;
  cursor: pointer;
}

.Popular-span {
  font-weight: 600;
  font-size: 25px;
  color: white;
}

@media screen and (max-width: 1280px) {
  .svg-span-div-main {
    left: 23rem;
  }

  .Near-You-img-div-span {
    width: 95%;
  }

  .NearYou-main-div {
    width: 100%;
  }
}
@media screen and (max-width: 1115px) {
  .svg-span-div-main {
    left: 18.5rem;
  }
}
@media screen and (max-width: 885px) {
  .svg-span-div-main {
    left: 18rem;
  }

  .Near-You-img-div-span {
    width: 95%;
  }

  .NearYou-main-div {
    width: 90%;
  }
}
@media screen and (max-width: 835px) {
  .svg-span-div-main {
    left: 16rem;
  }
}
@media screen and (max-width: 768px) {
  .svg-span-div-main {
    left: 13rem;
  }

  .Near-You-img-div-span {
    width: 95%;
  }

  .NearYou-main-div {
    width: 90%;
  }
}

@media screen and (max-width: 428px) {
  .svg-span-div-main {
    left: 13rem;
  }

  .Popular-span {
    font-size: 20px;
  }

  .Near-You-img-div-span {
    width: 90%;
  }

  .Laft-img {
    width: 40px;
  }

  .raghit-img {
    width: 40px;
  }

  .NearYou-main-div {
    width: 90%;
  }
}
