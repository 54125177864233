.Forget-password-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.forget-password-section-div {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.img-logo-forget {
  width: 200px;
}
.Forget-pass-span-tagg {
  font-size: 32px;
  font-weight: 550;
  text-align: center;
}
.Logo-span-mix {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.Plz-enter-email-span {
  text-align: center;
}
.Span-long-short {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  gap: 1rem;
}
.Enter-email-span-ppx {
  font-size: 20px;
}
.submit-btn-forget {
  width: 345px;
  height: 45px;
  background-color: black;
  color: white;
  border-radius: 5px;
}
.email-box-divv {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.Login-btn-forget {
  width: 345px;
  height: 45px;
  background-color: #9c04f5;
  color: white;
  border-radius: 5px;
}

@media screen and (max-width: 428px) {
  .Span-long-short {
    width: 90%;
  }
}
@media screen and (max-width: 376px) {
  .Enter-email-span-ppx {
    font-size: 19px;
  }
}
@media screen and (max-width: 360px) {
  .Enter-email-span-ppx {
    font-size: 18px;
  }
}
@media screen and (max-width: 320px) {
  .img-logo-forget {
    width: 100px;
  }
  .email-box-divv {
    width: 280px;
  }
  .Email-btn-submitt-divv {
    width: 97%;
  }
  .submit-btn-forget {
    width: 280px;
  }
  .Logo-span-mix {
    gap: 1rem;
  }
  .Span-long-short {
    gap: 0.1em;
  }
}
