.Login-main-sec {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.Login-div-main {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6rem;
  justify-content: center;
  margin: 3rem;
}

.img-div-p {
  position: relative;
}

.span-div-p {
  position: absolute;
  width: 300px;
  z-index: 1000000;
  left: 22rem;
  top: 22rem;
}

.home-button-ka-liya {
  display: flex;
  flex-direction: column;
}

.ya-do-button {
  display: flex;
}

.home-buttn-login {
  margin-top: 2rem;
  color: white;
  background-color: #9c04f5;
  cursor: pointer;
  border-radius: 4px;
  width: 343px;
  height: 45px;
}

.find-tha-span {
  font-weight: 700;
  font-size: 65px;
  color: #ffffff;
}

.girl-img-main {
  width: 760px;
  height: 800px;
}

.sedo-div {
  position: absolute;
  right: 37.5rem !important;
  overflow: hidden;
}

.perfect-span {
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
}

.i-m-g-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Login-logo-img {
  width: 60px;
  cursor: pointer;
}

.ho-span {
  font-weight: 700;
  font-size: 28.6963px;
  cursor: pointer;
}

.l-o-span {
  font-weight: 600;
  font-size: 24px;
}

.i-t-a {
  outline: none;
  width: 245px;
  height: 30px;
  border: none;
}

.svg-p-div {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.svg-margin {
  margin-left: 1rem !important;
  margin-bottom: 0.2rem !important;
}

.svg-margin-added {
  margin-left: 1rem !important;
  margin-bottom: 0.2rem !important;
  height: 22px;
  width: 22px;
}

.eaesy-img {
  margin-right: 1rem;
}

.che-div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.main-chek {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.chek-book {
  width: 20px !important;
  height: 20px !important;
  outline: none;
}

.me-span {
  font-weight: 400;
  font-size: 14px;
  color: #211b24;
}

.forgat-span {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  color: #211b24;
}

.Lo-btn-1 {
  background: #211b24;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  color: white;
  outline: none;
}

.btn-margin {
  margin-top: 2rem;
}

.line-div {
  width: 110px;
  border: 1px solid #dddddd;
  margin-top: 2rem;
  overflow: hidden;
  outline: none;
  height: 2px;
}

.line-div {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.line-img {
  width: 105px;
  height: 1px;
}

.line-span-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}

.f-book-btn {
  width: 164px;
  height: 45px;
  background: #fafcff;
  border: 1px solid #237aef;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.fac-book-span {
  font-weight: 700;
  font-size: 15px;
  color: #237aef;
}

.the-span-2 {
  font-weight: 700;
  font-size: 55px;
  color: white;
}

.g-btn-gogale {
  width: 164px;
  height: 45px;
  background: #fafcff;
  border: 1px solid #dc3030;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.gogale-login {
  font-weight: 700;
  font-size: 15px;
  color: #c61414;
}

.f-g-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.Guest-span {
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
}

.regi-span {
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
  cursor: pointer;
}

.dont-span {
  font-weight: 600;
  font-size: 14px;
}

.d-r-main-div {
  display: flex;
  gap: 0.3rem;
  margin-top: 1rem;
}

@media screen and (max-width: 1080px) {
  .girl-img-main {
    width: 500px;
    height: 500px;
  }

  .span-div-p {
    left: 8rem;
  }

  .sedo-div-2 {
    right: 30rem !important;
  }

  .p-input {
    right: 3rem;
  }
}

@media screen and (max-width: 913px) {
  .Login-div-main {
    display: flex;
    flex-direction: column;
  }

  .sedo-div-2 {
    right: 4.5rem !important;
    top: 3rem !important;
    position: absolute;
  }

  .p-input {
    right: 14rem;
  }

  .span-div-p {
    left: 12rem;
  }
}

@media screen and (max-width: 884px) {
  .Login-div-main {
    display: flex;
    flex-direction: column;
  }

  .sedo-div-2 {
    right: 0.3rem !important;
    top: 3rem !important;
  }

  .p-input {
    right: 14rem;
  }

  .span-div-p {
    left: 12rem;
  }
}

@media screen and (max-width: 768px) {
  .Login-div-main {
    display: flex;
    flex-direction: column;
  }

  .sedo-div-2 {
    right: 0.3rem !important;
    top: 3rem !important;
  }

  .p-input {
    right: 14rem;
  }

  .span-div-p {
    left: 12rem;
    top: 16rem;
  }
}

@media screen and (max-width: 540px) {
  .Login-div-main {
    display: flex;
    flex-direction: column;
  }

  .girl-img-main {
    width: 100%;
    height: 100%;
  }

  .span-div-p {
    left: 2rem;
    top: 11rem;
    display: none;
  }

  .main-chek {
    width: 90%;
  }

  .Login-div-main {
    margin: 0rem;
  }

  .find-tha-span {
    font-weight: 500;
    font-size: 40px;
  }

  .f-g-btn-div {
    gap: 1rem;
  }

  .i-m-g-div {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .span-div-p {
    width: 280px;
  }

  .Lo-btn-1 {
    width: 342px;
  }
  .img-div-p {
    display: none;
  }
}
