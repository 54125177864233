.MyProperty-main-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.MyProperty-main-div {
  min-height: 100vh;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  /* margin: 2rem; */
  margin-top: 2rem;
  flex-direction: column;
}

.circular {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular > * {
  color: #9c04f5 !important;
}

.svg-map-khud {
  display: flex;
  gap: 2rem;
}

.btn-similar {
  background: #ffffff;
  border: 1px solid #9c04f5;
  border-radius: 4px;
  width: 417px;
  height: 40px;
  color: #9c04f5;
  font-weight: 700;
  font-size: 14px;
}

.btn-na-mate-mhnat {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.mainnn-divvv-dusri-bar {
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0.8rem;
  width: 100%;
  width: 100%;
}

.Prop-home-1 {
  width: 418px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

.map-upar-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.my-prop-and-add {
  display: flex;
  width: 100% !important;
  justify-content: space-between;

  align-items: center;
  margin-bottom: 2rem;
}

.add-btn-my {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.My-prop {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.add-my-span {
  font-weight: 700;
  font-size: 14px;
  color: #9c04f5;
  cursor: pointer;
}

.api-imgis {
  width: 100%;
  height: 150px;
}

.Button-divv-property {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  height: 50px;
}

.Property-ppx-cvv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
}

.Property-ppx-Delete {
  display: flex;
  justify-content: center;
  height: 60px;
  width: 60%;
}

.spandivv-ppx-property {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0 1.5rem 0 1.5rem;
}

.Property-card-divv-ppx {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Property-card-divv-verify {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.btn-property-verify-main {
  background-color: #211b24;
  color: #ffffff;
  height: 40px;
  width: 50px;
  border-radius: 8px;
}

.Property-ppx-verify {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 80%;
}

.btn-property-stripe-main {
  background-color: #9c04f5;
  color: #ffffff;
  height: 35px;
  border-radius: 8px;
  width: 70px;
  font-size: 15px;
}

.btn-new-add-main {
  background-color: #9c04f5;
  color: #ffffff;
  height: 40px;
  border-radius: 8px;
  width: 140px;
  font-size: 15px;
}
.btnnn-pro-later-main {
  height: 35px;
  border-radius: 8px;
  width: 90px;
  background-color: #9c04f5;
  color: white;
}
.btn-pro-later-main {
  height: 40px;
  border-radius: 8px;
  width: 60px;
}
.btn-pro-later-con {
  height: 40px;
  border-radius: 8px;
  width: 140px;
  font-size: 15px;
}
.btn-pro-later-main {
  height: 40px;
  border-radius: 8px;
  width: 60px;
}
.btn-okk-later-main {
  height: 40px;
  border-radius: 8px;
  width: 60px;
  background-color: #9c04f5;
  color: #ffffff;
}
@media screen and (max-width: 1280px) {
  .MyProperty-main-section {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1115px) {
  .Prop-home-1 {
    width: 340px;
  }

  .btn-similar {
    width: 340px;
  }
}

@media screen and (max-width: 885px) {
  .MyProperty-main-div {
    width: 90%;
    display: flex;
    align-items: center;
    margin: 2rem;
    flex-direction: column;
    /* justify-content: center; */
  }

  .my-prop-and-add {
    width: 90%;
  }

  .MyProperty-main-section {
    width: 60%;
  }

  .My-profile-section-22 {
    width: 50%;
  }

  .mainnn-divvv-dusri-bar {
    width: 100%;
  }

  .MyProperty-main-section {
    width: 55%;
  }

  .btn-similar {
    width: 330px;
  }

  .Prop-home-1 {
    width: 330px;
  }
}

@media screen and (max-width: 768px) {
  .MyProperty-main-div {
    width: 90%;
    display: flex;
    align-items: center;
    margin: 2rem;
    flex-direction: column;
    /* justify-content: center; */
  }

  .my-prop-and-add {
    width: 100%;
  }

  .map-upar-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .My-profile-section-22 {
    width: 50%;
  }

  .mainnn-divvv-dusri-bar {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .Prop-home-1 {
    width: 230px;
  }

  .MyProperty-main-section {
    width: 75%;
  }

  .mainnn-divvv-dusri-bar {
    width: 100%;
  }

  .my-prop-and-add {
    margin-top: 0rem;
    display: flex;
    align-items: flex-start;
  }

  .MyProperty-main-div {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 1rem;
    flex-direction: column;
  }

  /* .my-prop-and-add {
    width: 250px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  } */

  .map-upar-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .svg-span-div-main {
    left: 11rem;
  }

  .Popular-span {
    font-size: 20px;
  }

  .Near-You-img-div-span {
    width: 90%;
  }

  .Laft-img {
    width: 40px;
  }

  .raghit-img {
    width: 40px;
  }

  .NearYou-main-div {
    width: 90%;
  }

  .btn-similar {
    width: 250px;
  }

  .My-prop {
    font-size: 16px;
  }

  .Property-ppx-cvv {
    width: 100% !important;
  }

  .Button-divv-property {
    width: 100% !important;
  }
}

@media screen and (max-width: 428px) {
  .Prop-home-1 {
    width: 230px;
  }

  .MyProperty-main-section {
    width: 75%;
  }

  .mainnn-divvv-dusri-bar {
    width: 100%;
  }

  /* .my-prop-and-add {
    margin-top: 0rem;
    display: flex;
    align-items: flex-start;
  } */

  .MyProperty-main-div {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 1rem;
    margin-top: 2rem;
    flex-direction: column;
    justify-content: flex-start;
  }

  .my-prop-and-add {
    /* width: 250px; */
    display: flex;
    /* flex-direction: column; */
    gap: 0.5rem;
  }

  .map-upar-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .spandivv-ppx-property {
    font-size: 17px;
  }
  .svg-span-div-main {
    left: 11rem !important;
  }

  .Popular-span {
    font-size: 20px;
  }

  .Near-You-img-div-span {
    width: 90%;
  }

  .Laft-img {
    width: 40px;
  }

  .raghit-img {
    width: 40px;
  }

  .NearYou-main-div {
    width: 90%;
  }

  .btn-similar {
    width: 210px;
  }

  .My-prop {
    font-size: 16px;
  }

  .Property-ppx-cvv {
    width: 100% !important;
  }

  .Button-divv-property {
    width: 100% !important;
    /* height: 75px; */
  }
}
