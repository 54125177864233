.MyProperty-main-div-90 {
  /* min-height: 100vh; */
  justify-content: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;
  margin-bottom: 2rem;
}
.width-filter-div {
  width: 97%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.map-upar-div-serach-filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 97%;
}
