.EditProfile-main-sectiom {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.EditProfile-main-div {
  width: 80%;
  display: flex;
  align-items: center;

  justify-content: center;
  margin: 3rem;
  flex-direction: column;
}

.Btn-submit-kare-too {
  width: 250px;
  height: 40px;
  background-color: #9c04f5;
  color: #dfdfdf;
  border-radius: 5px;
}

.backbtndiv {
  display: flex;
  flex-direction: column;
}

.pro-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.pic-img {
  width: 140px;
  height: 140px;
}

.cemara-img {
  width: 45px;
  height: 45px;
}

.pic-div {
}

.cemara-div {
  position: absolute;
  top: 6rem;
  left: 6rem;
}

.pic-cemara-div {
  width: 140px;
  position: relative;
  height: 140px;
}

.second-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.span-1 {
  font-weight: 400;
  font-size: 17px;
  color: #211b24;
}

.PhoneInputInput {
  outline: none !important;
  background: #ffffff !important;
  border-radius: 4px !important;
  border: none;
}

.PhoneInputInput::placeholder {
  color: #8d8d8d !important;
}

.phone-input {
  background: #f7f7f7;
  border-radius: 4px;
  padding: 0.5rem;
  width: 341px;
  height: 45px;
  display: flex;
  gap: 1rem;
  position: relative;
}

.text-boxx-fullname {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.Drop-country-tag {
  border: none;
  text-decoration: none;
  height: 30px;
  background-color: #ffffff;
  width: 40px;
  outline: none;
}
.text-boxx-host {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-right: 20px;
}

.PhoneInputInput::placeholder {
  color: #211b24 !important;
}

.PhoneInputCountry {
  gap: 0.5rem;
}

.mnb-phone {
  display: flex;
  align-items: center;
}

.dedra-input {
  width: 100%;
  outline: none;
  border: none;
  background: #fff;
}
.dedra-input-id {
  width: 100%;
  outline: none;
  border: none;
  background: #fff;
  /* margin-left: 1rem; */
}
/* .dedra-input-id::placeholder {
  margin-left: 1rem ;
} */

.drop-book {
  outline: none;
  background: #fff;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  border: 1px solid #d1d1d1 !important;
}

.line-edit {
  width: 340px;
  height: 1px;
  border: 1px solid #dfdfdf;
  margin-top: 3rem;
}

.Govern-span {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
}

.host-span-tagg-ger {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.host-spann-divv-en {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.Addddddd-btn {
  background: #9c04f5;
  border-radius: 4px;
  width: 68px;
  height: 40px;
  color: white;
  border: none;
}

.Submit-btn {
  background: #9c04f5;
  margin-top: 2rem;
  border-radius: 4px;
  width: 355px;
  height: 40px;
  color: white;
  border: none;
}

.btn-spannnn-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.mangal-khani {
  display: none;
}

.Ht-ml-for-imput {
  background: #f9f7fb;

  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: cover;
}

.pic-on-add {
  height: 120px;
  width: 120px;
  border-radius: 100px;
  overflow: hidden;
  object-fit: cover;
}

.Cemara-te-img {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 4.5rem;
  left: 4.5rem;
  z-index: 1000;
}
.Ek-svg-hu-me-nahi {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.add-kiya-muja {
  position: absolute;
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 100px;
}
.proff-main-photo {
  border-radius: 25px;
}
.full-name-bug-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.i-t-a-host-itm {
  outline: none;
  width: 280px;
  height: 30px;
  border: none;
  margin-left: 1rem;
}
@media screen and (max-width) {
  .Ek-svg-hu-me-nahi {
    width: 343px;
  }
}
