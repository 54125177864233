.MyBookings-main-section {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}

.My-book-divv-rated {
  margin-left: 2rem;
}

.Mybooking-main-div {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  margin: 2rem;
  gap: 2rem;

  justify-content: center;
}

.My-booking-imgg-div {
  height: 60px;
  width: 80px;
  border-radius: 12px;
}

.My-boking-div {
  object-fit: cover;
  height: 100%;
  width: 88px;
  border-radius: 8px;
}

.Upcoming-btn {
  background: #9c04f5;
  border-radius: 3px;
  width: 80px;
  height: 23px;
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 12px;
}

.bookings-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.my-Upcoming-booking {
  display: flex;
  gap: 1rem;
  width: 370px;
  height: 141px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  padding: 1rem;
}

.Grandview-texas-US-span {
  font-weight: 500;
  font-size: 15px;
  color: #211b24;
}

.Accessible-park-span {
  font-weight: 500;
  font-size: 13px;
  color: #838383;
}

.night-span-price {
  display: flex;
  gap: 0.3rem;
}

.ma-span {
  font-weight: 600;
  font-size: 15px;
  color: #211b24;
}

.nm-span {
  font-weight: 400;
  font-size: 13px;
  color: #838383;
}

.Completed-btn {
  border-radius: 3px;
  width: 80px;
  height: 23px;
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 12px;
  background: #27bd23;
}

.my-Completed-booking {
  display: flex;
  gap: 0.7rem;
  width: 370px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
}

.night-line {
  width: 369px;
  height: 1px;
  border: 1px solid #e1e1e1;
}

.bokin-m-2 {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.Star-Rating-combined-divv {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.Kalu-star-imgg-black {
  width: 16px;
  height: 16px;
}

.Rated-dot-zero-spann {
  font-size: 17px;
}
.middle-khan-diiv-book {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Rate-this-span {
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
  cursor: pointer;
}

.Rate-this-no-review {
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
}

.span-div-svg-icon {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  justify-content: space-between;
  width: 90%;
}

.Rate-divv-rated {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  justify-content: space-between;
  width: 90%;
}

.Rated-spannn-taggg {
  color: #979797;
  cursor: pointer;
}

.maindiv-to-card {
  display: flex;
  gap: 2rem;
}

.Rate-span {
  font-weight: 600;
  font-size: 14px;
}
.Not-found {
  display: flex;
  justify-content: center;
  width: 80%;
  height: 70px;
  align-items: center;
}
@media screen and (max-width: 884px) {
  .Mybooking-main-div {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .my-buking {
    width: 100%;
  }

  .my-Completed-booking {
    width: 260px;
  }

  .night-line {
    width: 260px;
  }
}

@media screen and (max-width: 768px) {
  .maindiv-to-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Mybooking-main-div {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .maindiv-to-card {
    display: flex;
    flex-direction: column;
  }

  .Mybooking-main-div {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .my-Completed-booking {
    width: 260px;
  }

  .MyBookings-main-section {
    width: 53%;
  }

  .night-line {
    width: 260px;
  }
}

@media screen and (max-width: 428px) {
  .maindiv-to-card {
    display: flex;
    flex-direction: column;
  }

  .Mybooking-main-div {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .my-Completed-booking {
    width: 260px;
  }

  .MyBookings-main-section {
    width: 53%;
  }

  .night-line {
    width: 260px;
  }
  .Not-found {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .My-boking-div {
    width: 80px;
  }

  .my-Completed-booking {
    width: 228px;
  }

  .night-line {
    width: 228px;
  }

  .Grandview-texas-US-span {
    font-size: 13px;
  }
}
