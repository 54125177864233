.RateThisProperty-sec {
  display: flex;
  flex-direction: column;

  border-radius: 15px;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-width: 23rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: white;
  max-height: 36rem;
}

.thard-div-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RateThisProperty-main-divv {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem;
  flex-direction: column;
  padding: 2rem;
}

.rating-img {
  width: 100px;
  height: 85px;
  border-radius: 7px;
}

.Grandview-div {
  display: flex;
  width: 80%;
  gap: 1.5rem;
}

.usa-span {
  font-weight: 500;
  font-size: 13px;
  color: #838383;
}

.us-usa-div {
  display: flex;
  flex-direction: column;
}

.us-span {
  font-weight: 500;
  font-size: 15px;
  color: #211b24;
}

.fastar-mnb {
  height: 40px;
  width: 40px;
}

.star-rating-div {
  display: flex;
  gap: 0.5rem;
}

.here-input-2 {
  width: 80%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  outline: none;
}

.text-span-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.span-you-div {
  width: 80%;
  margin-top: 2rem;
}

.Submit-btn-div-22 {
  background: #211b24;
  border-radius: 4px;
  color: white;
  border: none;
  outline: none;
  width: 303px;
  height: 45px;
  font-weight: 700;
  font-size: 15px;
}

.btn-close-img-dono {
  outline: none;
  border: none;
  background-color: white;
}

.close-btn-img {
  width: 30px;
}

@media screen and (max-width: 428px) {
  .RateThisProperty-main-divv {
    width: 90%;
  }

  .border-line {
    width: 350px;
  }
}

@media screen and (max-width: 360px) {
  .RateThisProperty-sec {
    width: 90%;
  }

  /* .svg-chaing {
        width: 76%;
    } */

  .border-line {
    width: 286px;
  }

  .star-rating-div {
    margin-top: 2px;
  }
}
