.BookingRequest-main-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.BookingRequest-main-div {
  width: 80%;
  display: flex;
  margin: 2rem;
  flex-direction: column;
}
.svg-or-span-ap {
  display: flex;
  justify-content: space-between;
}
/* Add more specificity to your custom CSS selector */
.custom-date-picker .rs-calendar-cell-disabled {
  color: #999 !important; /* Light grey color */
}
.plucha-and-maines {
  display: flex;
  gap: 2rem;
}
.svg-or-contain {
  display: flex;
  flex-direction: column;
}
.imagis-sab-aajao {
  display: flex;
  gap: 1rem;
}
.Pay-with-wihot {
  display: flex;
  margin-left: 5rem;
  justify-content: space-between;
}
.code-de-do {
  border: none;
  outline: none;
  background: #ffffff;
  width: 240px;
}
.code-book {
  outline: none;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  border-radius: 4px;
  width: 355px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.eror-colum {
  display: flex;
  flex-direction: column;
}
.mnb-phone-second-re {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.code-de-do::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #8d8d8d;
}
.margi-dedo {
  margin-left: 5rem;
}
.city-select-svg {
  margin-left: 1rem !important;
}
.applye-kiya {
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
  margin-right: 1rem;
  cursor: pointer;
}
.opan-div-second {
  width: 290px;
  height: 39px;
  border: none;
  outline: none;
  padding-left: 1rem;
}
.Name-on-span {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
}
.selact-kar-lo {
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
}
.kon-karega {
  width: 109.33px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ma-selact-hu {
  width: 90.33px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  border: none;
  outline: none;
}
.sab-ka-dad {
  display: flex;
  gap: 1rem;
}
.host-profile-main-user {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 40px;
}
.koi-bhi-ho-to {
  display: flex;
}
.cvv-bolte {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 32%;
}
.text-to-lekho {
  width: 100%;
  outline: none;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 1rem;
}
.cake-karo {
  display: flex;
  gap: 1rem;
}
.font-alag-he {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}
.terms-span {
  font-weight: 400;
  font-size: 13px;
  color: #9c04f5;
  text-decoration: none !important;
  outline: none;
  border: none;
  margin-left: 0.3rem;
}
.terms-span-terms {
  font-weight: 400;
  font-size: 13px;
  color: #9c04f5;
  text-decoration: none !important;
  outline: none;
  border: none;
}

.by-book-span {
  font-weight: 400;
  font-size: 13px;
  color: #211b24;
}
.rs-btn-close .rs-icon {
  display: none !important;
}
.accept-conditions-span {
  color: red;
}
.coupon-terms-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.seconad-div-main-ke-bad {
  background: #f3f3f3;
  width: 370px;
  margin-top: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 11px;
}
.Coupon-Selact-input-ho {
  width: 250px;
  border: none;
  outline: none;
}
.Guest-api-count {
  display: flex;
  gap: 0.5rem;
}
.Request-btnn {
  display: flex;
  justify-content: center;
}
.You-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.Diaabled-dates-cls-btnn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.Diabled-btnn-close {
  background-color: #9c04f5;
  color: #ffffff;
  height: 35px;
  width: 70px;
  border-radius: 5px;
}
.Invalid-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.Invalid-date-selection {
  font-size: 24px;
  font-weight: 600;
}
.rs-picker-toolbar-ranges {
  display: none !important;
}
@media screen and (max-width: 1280px) {
  .BookingRequest-main-div {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .Pay-with-wihot {
    display: flex;
    flex-direction: column;
  }
  .imagis-sab-aajao {
    display: flex;
    gap: 3rem;
  }
}
@media screen and (max-width: 884px) {
  .Pay-with-wihot {
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 822px) {
  .Pay-with-wihot {
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
  }
  .svg-or-span-ap {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .margi-dedo {
    margin-left: 0rem;
  }
  .selact-kar-lo {
    margin-left: 0rem;
  }
  .Pay-with-wihot {
    margin-left: 0rem;
  }
  .font-alag-he {
    display: flex;
    flex-direction: column;
  }
  .BookingRequest-main-div {
    width: 90%;
  }
  .Pay-with-wihot {
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 428px) {
  .margi-dedo {
    margin-left: 0rem;
  }
  .BookingRequest-main-section {
    margin-top: 0rem;
  }
  .selact-kar-lo {
    margin-left: 0rem;
  }
  .Pay-with-wihot {
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
  }
  .code-book {
    width: 300px;
  }
  .code-de-do {
    width: 180px;
  }
  .cvv-bolte {
    width: 96%;
  }
  .kon-karega {
    width: 90px;
  }
  .ma-selact-hu {
    width: 75px;
  }
  .by-book-span {
    width: 233px;
  }
  .terms-span {
    width: 150px;
  }
  .font-alag-he {
    display: flex;
    flex-direction: column;
  }
  .let-ho-gya {
    width: 300px;
  }
}
@media screen and (max-width: 360px) {
  .plucha-and-maines {
    gap: 0.5rem;
  }
}
