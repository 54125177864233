.My-Wallet-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.My-Wallet-div {
  width: 80%;
  display: flex;
  margin: 3rem;
  flex-direction: column;
}

.doler-div {
  display: flex;
  gap: 2rem;
}

.my-wall-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.pymant-dolar-div {
  width: 500px;
  height: 231px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  background: #faf4fd;
  border-radius: 6px;
}

.tan-k-div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.tan-line {
  height: 52px;
  width: 1px;
  border: 1px solid #e1e1e1;
}

.jugad-div {
  display: flex;
  gap: 3rem;
}

.Transfer-to-btn {
  background: #9c04f5;
  border-radius: 4px;
  width: 254px;
  height: 45px;
  color: white;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 15px;
}

.k-10 {
  font-weight: 600;
  font-size: 26px;
  color: #211b24;
}

.totel-span {
  font-weight: 400;
  font-size: 15px;
  color: #211b24;
}

.ignor-div {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.income-span {
  font-weight: 700;
  font-size: 20px;
  color: #211b24;
}

.histry-input-btn-div {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.submit {
  border: none !important;
  outline: none !important;
}

/* [type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    border: none;
    background-color: white;
} */

.khanie {
  border: none;
  background-color: white;
}
.d-p-sn {
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 2rem;
  background-color: #f3f3f3;
  border-radius: 4px;
}
.d-p-sn-div {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  /* background-color: #211b24; */
}

.store-name-div {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-left: 6px;
}

.date-price-div {
  display: flex;
  gap: 8rem;
  align-items: center;
}

.date-span {
  font-weight: 700;
  font-size: 14px;
  color: #211b24;
}

.price-span {
  font-weight: 700;
  font-size: 14px;
  color: #211b24;
  display: flex;
  width: 50px;
}

.store-span {
  font-weight: 700;
  font-size: 14px;
  color: #211b24;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Texas1-img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.Texas1-line {
  width: 100%;
  height: 1px;
  border: 1px solid #e4e4e4;
  margin-top: 1rem;
}

.date-price-11 {
  display: flex;
  gap: 4.2rem;
  align-items: center;
}

.price-span-1 {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
  margin-right: 5px;
  display: flex;
  width: 44px;
}

.date-span-1 {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
  width: 110px;
}

.store-span-1 {
  font-weight: 600;
  font-size: 15px;
  color: #211b24;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 884px) {
  .doler-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    align-items: center;
    width: 100%;
  }

  .My-Wallet-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .doler-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    align-items: center;
    width: 100%;
  }

  .My-Wallet-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 428px) {
  .doler-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .pymant-dolar-div {
    width: 100%;
  }

  .My-Wallet-div {
    margin: 0rem;
    width: 90%;
  }

  .store-name-div {
    width: 60%;
    gap: 1rem;
    align-items: center;
    /* justify-content: center; */
    display: flex;
    height: 40px;
  }

  .date-price-11 {
    gap: 1rem;
    display: flex;
  }

  .date-price-div {
    display: flex;
    gap: 4.8rem;
  }
}

@media screen and (max-width: 360px) {
  .store-name-div {
    width: 59%;
  }
}
