.Close-btnn-modall-divv {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Modal_error_taggg {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.Texas-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.storeco-div {
  width: 10%;
  display: flex;
  gap: 1rem;
  margin-left: 6px;
}
.Cohost_main_divvv_ppx {
  width: 80%;
}
.date_cohost_name {
  width: 80%;
}
.store-span_name {
  font-weight: 600;
  font-size: 20px;
  color: #211b24;
  display: flex;
  align-items: center;
}
.underr_the_lineee {
  width: 100%;
  border: 1px solid #e4e4e4;
}
.My-prop_cohost {
  font-size: 24px;
  font-weight: 600;
}
.my-prop-and-cohost {
  display: flex;
  /* width: 100% !important; */
  width: 80% !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
@media screen and (max-width: 428px) {
  .storeco-div {
    width: 30%;
    display: flex;
    gap: 1rem;
    margin-left: 6px;
  }
  .My-prop_cohost {
    font-size: 20px;
  }
}
