.mobile-otp-span {
  font-weight: 400;
  font-size: 14px;
  color: #8c8580;
}
.numbar-span {
  font-weight: 600;
  font-size: 14px;
  color: #171310;
}
.Back-span {
  display: flex;
  align-items: center;
}
