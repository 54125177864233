.Apply-for-Time-MAin-divv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.Apply-for-Time-section-two {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Timeshare-check-box-divv {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Check-boxx-apply-divv {
  font-size: 30px;
  font-weight: 600;
  color: #9c04f5;
}
.Boxxes-main-items {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}
.box-check-spann {
  font-size: 20px;
}
.check-box-input-divv {
  width: 20px;
}
.Beds-room-select-item {
  display: flex;
  flex-direction: column;
}
.Beds-items-thard-section {
  width: 70%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
}
.open-beds-selact-tag {
  width: 433px;
  height: 40px;
  border: none;
  outline: none;
  padding-left: 1rem;
}
.flex-kar-dono-ko-divv {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.apply-btnn-divv-time {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  height: 90px;
}
.btn-apply-to-submit-form {
  width: 360px;
  height: 40px;
  border-radius: 6px;
  background-color: #9c04f5;
  color: #d1d1d1;
  font-weight: 600;
  font-size: 16px;
}
@media screen and (max-width: 428px) {
  .Apply-for-Time-section-two {
    width: 90%;
  }
  .btn-apply-to-submit-form {
    width: 100%;
  }
  .Check-boxx-apply-divv {
    font-size: 25px;
  }
  .box-check-spann {
    font-size: 18px;
  }
}
@media screen and (max-width: 360px) {
  .Apply-for-Time-section-two {
    width: 91%;
  }
}
