.BookingDetails-maim-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.booking-main-diav {
  width: 80%;
  display: flex;

  margin: 2rem;
  flex-direction: column;
}
.trip-img-div {
  width: 101px;
  height: 71px;
  border-radius: 8px;
}
.span-dada-khud {
  font-weight: 500;
  font-size: 15px;
  color: #211b24;
}
.Accessible-span-dusra {
  font-weight: 500;
  font-size: 13px;
  color: #838383;
}
.spaan-bhai-ap-ho {
  display: flex;
  flex-direction: column;
  width: 200px;
}
.muja-phchano-ma-hu-kon {
  display: flex;
  gap: 1rem;
}
.yor-dets {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
}
.trip-enjoy {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #211b24;
}
.dates-karage {
  font-weight: 600;
  font-size: 15px;
  color: #211b24;
}
.pacha-char {
  font-weight: 500;
  font-size: 13px;
  color: #838383;
}
.koi-bhi-ho {
  width: 91%;
  height: 1px;
  border: 1px solid #dfdfdf;
}
.aajao-line-end-me {
  display: flex;
  justify-content: flex-end;
}
.text-ke-dad {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.Pay-with-do {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
}
.img-ko-sahi-kare {
  width: 63px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 0.3rem;
}
.visa-ko-chota-kare {
  width: 50px;
}
.dolar-ma-pya-karo {
  font-weight: 400;
  font-size: 15px;
  color: #211b24;
}
.aju-baju-niklo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rupiya-pya {
  font-weight: 600;
  font-size: 15px;
  color: #211b24;
}
.khtam-hona-ko-he {
  display: flex;
  width: 350px;
}
.img-ko-satekaru {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.Jerome-span {
  font-weight: 600;
  font-size: 15px;
  color: #211b24;
}
.text-set {
  font-weight: 400;
  font-size: 13px;
  color: #211b24;
}
.Cancel-Reques-btn {
  width: 286px;
  height: 45px;
  background: #9c04f5;
  border-radius: 4px;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  border: none;
  outline: none;
}
.Date-piker-js {
  display: flex;
  flex-direction: column;
}
.InputElement is-empty Input Input--empty {
  display: none;
}
.Property-divv-cancel {
  font-size: 25px;
  font-weight: 600;
}
.Property-card-divv-cancel {
  margin: 5px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 769px) {
  .yor-dets {
    margin-left: 1rem;
  }
  .Pay-with-do {
    margin-left: 1rem;
  }
  .text-ke-dad {
    width: 60%;
  }
}
@media screen and (max-width: 540px) {
  .muja-phchano-ma-hu-kon {
    display: flex;
    flex-direction: column;
  }
  .text-ke-dad {
    width: 100%;
  }
  .yor-dets {
    margin-left: 0rem;
  }
  .Pay-with-do {
    margin-left: 0rem;
  }
  .text-set {
    width: 300px;
  }
  .koi-bhi-ho {
    width: 100%;
  }
  .spaan-bhai-ap-ho {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 428px) {
  .muja-phchano-ma-hu-kon {
    display: flex;
    flex-direction: column;
  }
  .text-ke-dad {
    width: 100%;
  }
  .yor-dets {
    margin-left: 0rem;
  }
  .Pay-with-do {
    margin-left: 0rem;
  }
  .text-set {
    width: 300px;
  }
  .koi-bhi-ho {
    width: 100%;
  }
  .spaan-bhai-ap-ho {
    margin-bottom: 1rem;
  }
}
