.api-deta-get {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  padding: 1rem 1rem;
}
.wish-span-ppx {
  font-size: 30px;
  font-weight: 600;
}
.Wishlist-main-section-Two {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
.Wishlist-main-div-two {
  /* width: 80%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.wishlist-div {
  display: flex;
  width: 100%;
  gap: 10px;
}
.bangloz-img-Wisha {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.bangloz-img-Wisha {
  width: 300px;
}
.mainnn-wish-svg {
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 0.8rem;
  width: 300px;
}
.mainnn-wish-svg > img {
  width: 100%;
}
.svg-span-wish-main {
  background: #fee24c;
  border-radius: 3px;
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  gap: 3px;
  text-align: center;
  padding: 5px;
  position: absolute;
  top: 1.7rem;
  left: 14rem;
}
.circular3 {
  width: 100%;
  height: 50vh;
  display: grid;
  place-items: center;
}
.circular3 > * {
  color: #fee24c !important;
}
.new-class-span {
  display: flex !important;
}
@media screen and (max-width: 1080px) {
  .wishlist-div {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 884px) {
  .Wishlist-main-div-two {
    width: 80%;
  }
}
@media screen and (max-width: 834px) {
  .Wishlist-main-div-two {
    width: 75%;
  }
}
@media screen and (max-width: 820px) {
  .Wishlist-main-div-two {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  .Wishlist-main-div-two {
    width: 65%;
  }
}
@media screen and (max-width: 768px) {
  .Wishlist-main-div-two {
    width: 60%;
  }

  .map-upar-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 428px) {
  .mainnn-wish-svg {
    width: 100%;
  }
  .svg-span-wish-main {
    left: 11.5rem;
  }
  .api-deta-get {
    width: 74%;
  }
  .new-class-span {
    width: 230px;
  }
  .Wishlist-main-div-two {
    width: 75%;
  }
}
