.BecomeHost-sec {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fffae2;
  flex-direction: column;
  display: none;
}
.BecomeHost-main-div {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
}
.next-div {
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 80%;
}
.fingar-img {
  width: 100px;
}
.r-span {
  font-weight: 700;
  font-size: 30px;
}
.span-r-div {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.r-span2 {
  font-weight: 400;
  font-size: 19px;
  color: #211b24;
}
.fair-span {
  font-weight: 400;
  font-size: 19px;
  color: #211b24;
}
.div-2-main {
  display: flex;
  flex-direction: column;
}
.btn-divo {
  display: flex;
  gap: 1rem;
}
.out-btn {
  width: 157px;
  height: 45px;
  background: #211b24;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  border: none;
}
.b-btn {
  width: 167px;
  height: 45px;
  background: #9c04f5;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;
  border: none;
}
.tir-img {
  width: 80px;
}
@media screen and (max-width: 884px) {
  .next-div {
    width: 90%;
  }
  .BecomeHost-main-div {
    width: 95%;
  }
  .fair-span {
    font-size: 18px;
  }
  .r-span2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .next-div {
    width: 95%;
  }
  .BecomeHost-main-div {
    width: 95%;
  }
  .fair-span {
    font-size: 16px;
  }
  .r-span2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 428px) {
  .next-div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .BecomeHost-main-div {
    width: 90%;
  }
  .btn-divo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .out-btn {
    width: 120px;
  }
  .b-btn {
    width: 120px;
  }
  .BecomeHost-sec {
    margin-bottom: 0rem;
  }
}
