.PropertyType-main-section {
  display: flex;
  width: 790px;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  z-index: 100000;
  background-color: white;
  border-radius: 12px;
}

.filter-main-spann-ppx {
  display: flex;
  justify-content: center;
}

.filter-span-tag-ppx {
  font-weight: 600;
  font-size: 30px;
}

.PropertyType-main-div {
  width: 80%;
  display: flex;
  flex-direction: column;

  margin: 3rem;
}

.property-ke-type {
  display: flex;

  gap: 0.5rem;
  flex-direction: column;
}

.imgg-divv-title-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
  border-radius: 4px;
  border: 2px solid #ececec;
  width: 500px;
  height: 100px;
  justify-content: center;
  gap: 0.5rem;
}

.svg-muja-khta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f8f8f8;
  border-radius: 3.95389px;
  width: 82.04px;
  height: 82.04px;
  align-items: center;
  text-align: center;
  gap: 0.2rem;
}

.house-mera {
  color: #211b24;
  font-weight: 400;
  width: 136px;
  font-size: 13px;
  text-align: center;
}

.house-mera-dusra {
  color: #211b24;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Proper-tyType-span {
  font-weight: 700;
  font-size: 18px;
  color: #211b24;
}

.svg-sab-span-sab {
  display: flex;
  gap: 0.5rem;
}

.sab-ko-ek-line-ma {
  display: flex;
  gap: 3rem;
}

.any-btn-div {
  /* background: #9c04f5;  */
  border-radius: 4px;
  width: 54px;
  height: 33px;
  color: white;
  border: none;
}

.any-btn-main-div {
  display: flex;
  gap: 1rem;
}

.one-btn {
  background: #ffffff;
  border: 1px solid #afafaf;
  border-radius: 4px;
  width: 37px;
  height: 33px;
}
.button {
  background: #ffffff;
  border: 1px solid #afafaf;
  border-radius: 4px;
  width: 44px;
  height: 33px;
}

.button.selected {
  /* Styles for the selected button */
  background-color: #9c04f5;
  color: white;
  width: 44px;
  height: 33px;
  border-radius: 4px;
}

.any-btn-1 {
  width: 54px;
  height: 33px;
  background: #ffffff;
  border: 1px solid #afafaf;
  border-radius: 4px;
}

.samed {
  display: flex;

  gap: 1rem;
}

.wifi-span {
  font-weight: 600;
  font-size: 14px;
  color: #211b24;
}

.input-chck-div {
  display: flex;
  flex-direction: column;
}

.chack-bot-te {
  display: flex;
  width: 280px;
  justify-content: space-between;
}

.chack-bot {
  display: flex;
  width: 270px;
  justify-content: space-between;
}

.chack-bot-te {
  display: flex;
  width: 290px;
  justify-content: space-between;
}

.input-spn-same {
  display: flex;
  gap: 1rem;
}

.Entire-Place-span {
  font-weight: 600;
  font-size: 17px;
  color: #211b24;
}

.place-span {
  font-weight: 500;
  font-size: 13px;
  color: #838383;
}

.span-dada-kon {
  display: flex;
  flex-direction: column;
}

.sow-to {
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
  background-color: white;
}

.red-more-span {
  font-weight: 600;
  font-size: 14px;
  color: #9c04f5;
}

.main-drop {
  margin-left: 0.5rem;
}

.chack-bot-te-apko {
  display: flex;
  width: 290px;
  justify-content: space-between;
}

.check-kar-patapat {
  display: flex;
  flex-direction: column;
}
.apply-btnn-filter {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btnn-to-apply-filter {
  width: 300px;
  height: 40px;
  background-color: black;
  color: #ffffff;
  border-radius: 8px;
}
.img-tiltle-main-dixx-ppx {
  overflow-x: scroll;
  display: flex;
  gap: 20px;
  width: 700px;
}
.imgg-divv-itmm {
  width: 30px;
  height: 30px;
}
.range-img-style-divv {
  height: 6px;
  width: 100%;
  background-color: #9c04f5;
}
@media screen and (max-width: 1026px) {
  .PropertyType-main-section {
    width: 540px;
  }
  .range-set-divv {
    width: 100%;
  }
  .any-btn-main-div {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 884px) {
  .sab-ko-ek-line-ma {
    display: flex;
    flex-direction: column;
  }
  .PropertyType-main-section {
    width: 570px;
  }
  .raing-de {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .sab-ko-ek-line-ma {
    display: flex;
    flex-direction: column;
  }

  .raing-de {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .sab-ko-ek-line-ma {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .chack-bot-te {
    width: 300px;
  }
  .rating-de {
    display: flex;
    justify-content: center;
    width: 100% !important;
  }
  .modal-ppx-modal-divv {
    width: 100%;
  }
  .any-btn-main-div {
    overflow-x: scroll;
  }
  .PropertyType-main-section {
    width: 100%;
  }
  .sabb-ek-divv-me-ppx {
    /* display: flex; */
    /* justify-content: center; */
    width: 100%;
  }
}
@media screen and (max-width: 428px) {
  .PropertyType-main-div {
    margin: 2rem;
  }
}
